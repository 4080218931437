<div>
  <div class="footer-montain"></div>
  <footer id="fale-conosco" class="footer">
    <div class="container-standard">
      <img src="assets/images/footer/logo-adrenalina.png" alt="logo-adrenalina">
    </div>
    <div class="container">
      <div class="footer-col footer-col-primary">
        <h2 class="footer-title">Descubra tudo sobre a <strong>Promoção</strong></h2>
        <div class="footer-list">
          <a class="footer-list-link" href="javascript:void('');" (click)="onNavScroll('home')">Home</a>
          <a class="footer-list-link" href="javascript:void('');" (click)="onNavScroll('faq')">FAQ</a>
          <a class="footer-list-link" href="javascript:void('');" (click)="onNavScroll('como-participar')">A
            Campanha</a>
          <a class="footer-list-link" href="javascript:void('');" (click)="onNavScroll('fale-conosco')">Fale conosco</a>
          <a class="footer-list-link" href="javascript:void('');" (click)="onNavScroll('premios')">Premiação</a>
          <a class="footer-list-link" target="_blank" download href="assets/files/Regulamento-AdrenalinaVW25.pdf">Regulamento</a>
        </div>
      </div>
      <div class="footer-col footer-col-secondary">
        <h2 class="footer-title"><strong>Dúvidas?</strong> Entre em contato conosco.</h2>
        <fieldset class="form-custom-secondary">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="input-material">
                <input type="text" maxlength="250" required
                       [(ngModel)]="model.name"
                       id="formFooter1"
                       name="formFooter1"/>
                <label for="formFooter1">*Nome</label>
              </div>
              <div class="input-material">
                <input type="text" maxlength="250" required
                       [(ngModel)]="model.email"
                       id="formFooter2"
                       name="formFooter2"/>
                <label for="formFooter2">*E-mail</label>
              </div>
              <div class="input-material">
                <input type="text" maxlength="250" required
                       [(ngModel)]="model.phone"
                       id="formFooter3"
                       name="formFooter3"
                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                       [dropSpecialCharacters]="false"
                       [clearIfNotMatch]="true"
                       (blur)="onClearModel($event, 'phone')"
                />
                <label for="formFooter3">*Telefone</label>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="input-material">
                <input type="text" maxlength="250" required
                       [(ngModel)]="modelSubject"
                       id="formFooter4"
                       name="formFooter4"/>
                <label for="formFooter4">*Assunto</label>
              </div>
              <div class="input-material">
                <textarea name="formFooter5" id="formFooter5"
                          [(ngModel)]="modelMessage"></textarea>
                <label for="formFooter5">*Mensagem</label>
              </div>
              <a style="color: #001E50;" href="javascript:void('');" class="btn btn-block btn-secondary"
                 (click)="onSend()">Enviar</a>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="footer-sponsor" *ngIf="true">
      <div class="footer-sponsor-bg">
        <div class="footer-sponsor-bg-title">
          <h3>Parceiros</h3>
        </div>
        <img src="assets/images/footer/footer-detail-1.png" alt="footer-detail-2.png">
      </div>
      <div class="icon">
        <img class="details" src="assets/images/footer/footer-detail-1.png" alt="footer-detail-1.png">
        <div class="footer-sponsor-icons">
          <img src="assets/images/maxi-performance-small-white.png" alt="">
          <!--<img src="assets/images/stpwhite.png" alt="">-->
          <img src="assets/images/tirreno-white.png" alt="">
          <img src="assets/images/wurth-white.png" alt="">
          <img src="assets/images/interozone-white.png" alt="">
          <img src="assets/images/brazzo.png" alt="">
        </div>
        <img class="details" src="assets/images/footer/footer-detail-2.png" alt="footer-detail-2.png">
      </div>
      <div class="footer-sponsor-line"></div>
      <div class="footer-sponsor-logo">
        <img src="assets/images/logo-vw.png" alt="Logo"/>
      </div>
    </div>
  </footer>
</div>
