import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxMaskModule} from 'ngx-mask';
import {EventBusService} from './service/event-bus.service';
import {CouponService} from './service/coupon.service';
import {ContactService} from './service/contact.service';
import {CURRENCY_MASK_CONFIG, CurrencyMaskModule} from 'ng2-currency-mask';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './views/home/home.component';
import {ContactComponent} from './views/contact/contact.component';
import {RegisterComponent} from './views/register/register.component';
import {LoginComponent} from './views/login/login.component';
import {WinnersComponent} from './views/winners/winners.component';
import {TermsComponent} from './views/terms/terms.component';
import {RecaptchaModule} from 'angular-google-recaptcha';
import {IconSpriteModule} from 'ng-svg-icon-sprite';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {FooterComponent} from './views/footer/footer.component';
import {HeaderComponent} from './views/header/header.component';
import {RegisterCouponComponent} from './views/register-coupon/register-coupon.component';
import {ModalMyGradesComponent} from './views/component/modal-my-grades/modal-my-grades.component';
import {ModalLuckyNumberComponent} from './views/component/modal-lucky-number/modal-lucky-number.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {OnlyNumberDirective} from './directive/only-number.directive';
import {registerLocaleData} from '@angular/common';
import localPt from '@angular/common/locales/pt';
import {GtagModule} from 'angular-gtag';
import {PartnersComponent} from './views/partners/partners.component';
import {RankingComponent} from './views/ranking/ranking.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PartnersDetailComponent} from './views/partners-detail/partners-detail.component';
import {NgxPaginationModule} from "ngx-pagination";
import {SoonComponent} from './views/soon/soon.component';
import {MatTabsModule} from "@angular/material/tabs";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RankingDetailModule} from "./views/ranking/ranking-detail/ranking-detail.module";

registerLocaleData(localPt, 'pt');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    ContactComponent,
    RegisterComponent,
    LoginComponent,
    WinnersComponent,
    TermsComponent,
    FooterComponent,
    HeaderComponent,
    RegisterCouponComponent,
    ModalMyGradesComponent,
    ModalLuckyNumberComponent,
    OnlyNumberDirective,
    RecoveryComponent,
    PartnersComponent,
    RankingComponent,
    PartnersDetailComponent,
    SoonComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        CurrencyMaskModule,
        SlickCarouselModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        // IconSpriteModule,
        GtagModule.forRoot({trackingId: 'UA-G-JPNZ2DSFZB', trackPageviews: true}),
        IconSpriteModule.forRoot({path: 'assets/sprites/sprite.svg'}),
        RecaptchaModule.forRoot({
            siteKey: '6Lf5xoEcAAAAAOmsoNCcR-lXOXHorPiV835x0cIg',
        }),
        AngularSvgIconModule.forRoot(),
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GoogleChartsModule,
        NgMultiSelectDropDownModule.forRoot(),
        MatTabsModule,
        RankingDetailModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: getLocaleTag,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderHttpInterceptor,
      multi: true
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: {   // BRL
        align: 'left',
        allowNegative: true,
        decimal: ',',
        precision: 2,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.'
      }
    },
    EventBusService,
    CouponService,
    ContactService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function getLocaleTag() {
  return getMeta('store-locale', 'pt-BR');
}

export function getCurrencyTag() {
  return getMeta('store-currency', 'BRL');
}

function getMeta(metaName, defaultValue) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return defaultValue;
}
