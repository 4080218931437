import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {RankingCategoryDetail} from "../../../model/ranking/ranking-category-detail";
import {Router} from "@angular/router";
import {UserService} from "../../../service/user.service";

declare var $: any;

@Component({
  selector: 'app-ranking-detail',
  templateUrl: './ranking-detail.component.html',
  styleUrls: ['./ranking-detail.component.scss']
})
export class RankingDetailComponent extends BaseComponent implements OnInit {

  @Input() rankingCategoryDetails: RankingCategoryDetail = new RankingCategoryDetail();

  constructor(public router: Router,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
  }

}
