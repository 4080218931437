<section id="home" class="home ts-background">
  <div class="home-bg-video">
    <video autoplay muted loop playsinline src="assets/videos/bg-blue.mp4"></video>
    <img src="assets/images/login/bg_blue.jpg" alt="bg_blue" />
  </div>

  <img class="home_big-logo" src="assets/images/logo-big.png" alt="big">
  <img class="home_tokyo thumbnail" src="assets/images/login/montanha-1.png" alt="montanha 1">
  <img class="home_beto thumbnail" src="assets/images/login/montanha-2.png" alt="montanha 2">
</section>


<section id="como-participar" class="campaign bg-white">
  <div class="bg-primary">&nbsp;</div>

  <div class="campaign_stripe">
    <div class="container">
      <h3 class="campaign_subtitle">A hora é agora!</h3>
      <img class="campaign_stripe_triangle" src="assets/images/home/triangles.png" alt="bg_blue"/>
    </div>
  </div>

  <!--  PREMIAÇÃO INTERMEDIARIA-->
  <div class="container">
    <div class="column mb-4">
      <div class="col-lg-8">
        <p class="campaign_description">
          A Campanha Adrenalina 2025 está começando, e desta vez, o desafio será mais emocionante do que nunca.
        </p>
        <p class="home_text">
          Prepare-se para uma jornada desafiadora que testará sua coragem e determinação. A primeira etapa da campanha
          leva você à deslumbrante Chapada dos Guimarães, um paraíso ecológico com cachoeiras e formações rochosas
          incríveis.
        </p>
        <p class="home_text">
          Se busca ainda mais emoção, a segunda etapa oferece a chance de explorar a Croácia, com suas praias turquesas
          e rica cultura.
        </p>
        <p class="home_text">
          Independentemente de onde você esteja, sua força e determinação são essenciais para conquistar esses destinos.
        </p>
        <p class="home_text">
          Está pronto(a) para aceitar o desafio e transformar seus sonhos em realidade? Vamos juntos alçar voos sem
          limites!
        </p>
      </div>
      <div class="campaign_row-card flex-lg-row flex-column">
        <div class="campaign_card">
          <span class="campaign_card_title">Quando?</span>
          <div class="d-flex flex-column position-relative">
            <span class="text-white campaign_card_items top">Outubro de <strong>2024</strong></span>
            <svg-icon src="assets/svg/arrow-card.svg"></svg-icon>
            <span class="text-white campaign_card_items bottom">Agosto de <strong>2025</strong></span>
          </div>
        </div>
        <div class="campaign_card big">
          <span class="campaign_card_title">Quais são as <br>categorias?</span>
          <span class="campaign_card_description">
            As concessionárias concorrerão em 4 categorias, no âmbito nacional, de acordo com o seu objetivo de compras validado no PAC Pós-Vendas 2024/2025 (Potencial Anual do Concessionário).
          </span>
        </div>
        <div class="campaign_card small">
          <span class="campaign_card_title">Quem<br>participa?</span>
          <span class="campaign_card_description">
            Todas as Concessionárias Volkswagen Automóveis com concessão plena, e PATs que tenham sido nomeados até 30 de setembro de 2024.
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 campaign_bg big-m" id="premios">
    <div class="campaign_award">
      <div class="campaign_award-bg-video">
        <video src="assets/videos/bg-award-card.mp4" muted autoplay loop playsinline></video>
        <div class="gradient-overlay"></div>
        <div class="container">
          <div class="campaign_award_content col-12">
            <span class="campaign_award_content_title">Premiação Intermediária</span>
            <div class="col-lg-4 d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <svg-icon src="assets/svg/award-card-icon1.svg"></svg-icon>
                <span class="text-primary mt-2"><strong>Gerentes</strong></span>
              </div>
              <svg-icon src="assets/svg/add-award.svg"></svg-icon>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <svg-icon src="assets/svg/award-card-icon2.svg"></svg-icon>
                <span class="text-primary mt-2"><strong>Equipes</strong></span>
              </div>
              <!--<svg-icon src="assets/svg/add-award.svg"></svg-icon>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <svg-icon src="assets/svg/award-card-icon2.svg"></svg-icon>
                <span class="text-primary mt-2"><strong>Acompanhantes</strong></span>
              </div>-->
            </div>
          </div>
        </div>
        <div class="campaign_award_footer">
          <div class="campaign_award_footer_brand">
            <img src="assets/images/home/brand-award.png" alt="brand-award">
          </div>
          <div class="col-12 campaign_award_footer_lbl small">
            <div class="container">
              <span>Nacional</span>
            </div>
          </div>
          <div class="col-12 campaign_award_footer_lbl full">
            <div class="container">
              <span>Chapada dos Guimarães</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="campaign_pontuaction">
    <div class="w-50 campaign_pontuaction_description">
      <div class="container p-6 d-flex justify-content-center align-items-center flex-column">
        <span class="campaign_pontuaction_description_lbl">A Etapa Intermediária terá avaliação nacional, dentro das quatro categorias (A, B, C e D), pontuando mensalmente as seguintes concessionárias:</span>
        <div class="container campaign_pontuaction_description_content">
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria A</dt>
            <dd class="home_list_value primary">7 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria B</dt>
            <dd class="home_list_value primary">6 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria C</dt>
            <dd class="home_list_value primary">5 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria D</dt>
            <dd class="home_list_value primary">4 primeiras concessionárias</dd>
          </dl>
        </div>
        <span class="campaign_pontuaction_description_lbl mt-4">O fechamento da Etapa Intermediária ocorrerá com base na pontuação acumulada até o dia 30/04/2025</span>
      </div>
    </div>
    <div class="campaign_pontuaction_grid">
      <div class="d-flex w-100">
        <img style="width: 33.3%;" src="assets/images/home/br/1.jpg" alt="cr-1">
        <img style="width: 33.3%;" src="assets/images/home/br/2.jpg" alt="cr-2">
        <img style="width: 33.4%;" src="assets/images/home/br/3.jpg" alt="cr-3">
      </div>
      <div class="d-flex w-100">
        <img style="width: 66.6%;" src="assets/images/home/br/4.jpg" alt="cr-4">
        <img style="width: 33.3%;" src="assets/images/home/br/5.jpg" alt="cr-3">
      </div>
    </div>
  </div>


  <!--  PREMIAÇÃO FINAL-->
  <div class="w-100 bg-purple purple-top">
    <div class="col-12 col-lg-8 mx-auto">
      <div class="awards_card no-bg rounded-0">
        <div class="table-responsive">
          <table class="table-alternative w-100">
            <thead class="thead-dark">
            <tr class="w-100">
              <th scope="col" class="text-white">Categorias</th>
              <th class="text-center bottom-bar" scope="col">A</th>
              <th class="text-center bottom-bar" scope="col">B</th>
              <th class="text-center bottom-bar" scope="col">C</th>
              <th class="text-center bottom-bar" scope="col">D</th>
              <th class="text-center bottom-bar" scope="col"><strong>Total</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Quantidade de concessionárias premiadas</td>
              <td class="text-center">7</td>
              <td class="text-center">6</td>
              <td class="text-center">5</td>
              <td class="text-center">4</td>
              <td class="text-center">22</td>
            </tr>
            <tr>
              <td>Quantidade de gerentes premiados por concessionária</td>
              <td class="text-center">2</td>
              <td class="text-center">2</td>
              <td class="text-center">2</td>
              <td class="text-center">2</td>
              <td class="text-center">8</td>
            </tr>
            <tr>
              <td>Quantidade de funcionários premiados por concessionária</td>
              <td class="text-center">3</td>
              <td class="text-center">3</td>
              <td class="text-center">2</td>
              <td class="text-center">2</td>
              <td class="text-center">10</td>
            </tr>
            <tr>
              <td>Total de premiados por concessionária</td>
              <td class="text-center">5</td>
              <td class="text-center">5</td>
              <td class="text-center">4</td>
              <td class="text-center">4</td>
              <td class="text-center">18</td>
            </tr>
            <tr>
              <td>Total de premiados por categoria</td>
              <td class="text-center">35</td>
              <td class="text-center">30</td>
              <td class="text-center">20</td>
              <td class="text-center">16</td>
              <td class="text-center">101</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 campaign_bg">
    <div class="campaign_award">
      <div class="campaign_award-bg-video">
        <video src="assets/videos/croacia-video.mp4" muted autoplay loop playsinline></video>
        <div class="gradient-overlay croacia"></div>
        <div class="container">
          <div class="campaign_award_content all-white col-12">
            <span class="campaign_award_content_title">Premiação Final</span>
            <div class="col-lg-3 d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <svg-icon src="assets/svg/award-card-icon1-white.svg"></svg-icon>
                <span class="text-white mt-2"><strong>Gerentes</strong></span>
              </div>

              <svg-icon src="assets/svg/add-award-white.svg"></svg-icon>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <svg-icon src="assets/svg/award-card-icon2-white.svg"></svg-icon>
                <span class="text-white mt-2"><strong>Acompanhantes</strong></span>
              </div>
            </div>
          </div>
        </div>
        <div class="campaign_award_footer">
          <div class="campaign_award_footer_brand final-award">
            <img src="assets/images/home/croacia-logo.png" alt="brand-award">
          </div>
          <div class="col-12 campaign_award_footer_lbl small bg-red">
            <div class="container">
              <span class="text-white">Internacional</span>
            </div>
          </div>
          <div class="col-12 campaign_award_footer_lbl medium bg-white">
            <div class="container">
              <span class="text-primary">Croácia</span>
            </div>
          </div>
          <div class="col-12 campaign_award_footer_lbl full bg-purple">
            <div class="container">
              <span class="d-none">Chapada dos Guimarães</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="campaign_pontuaction">
    <div class="w-50 campaign_pontuaction_description">
      <div class="container p-6 d-flex justify-content-center align-items-center flex-column">
        <span class="campaign_pontuaction_description_lbl">A etapa final terá avaliação nacional dentro das quatro categorias (A, B, C e D), premiando da seguinte forma:</span>
        <div class="container campaign_pontuaction_description_content">
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria A</dt>
            <dd class="home_list_value primary">3 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria B</dt>
            <dd class="home_list_value primary">2 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria C</dt>
            <dd class="home_list_value primary">2 primeiras concessionárias</dd>
          </dl>
          <dl class="home_list">
            <dt class="home_list_lbl primary">Categoria D</dt>
            <dd class="home_list_value primary">2 primeiras concessionárias</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="campaign_pontuaction_grid">
      <div class="d-flex w-100">
        <img style="width: 33.3%;" src="assets/images/home/cr/1.jpg" alt="cr-1">
        <img style="width: 33.3%;" src="assets/images/home/cr/2.jpg" alt="cr-2">
        <img style="width: 33.4%;" src="assets/images/home/cr/3.jpg" alt="cr-3">
      </div>
      <div class="d-flex w-100">
        <img style="width: 66.6%;" src="assets/images/home/cr/4-a.jpg" alt="cr-4">
        <img style="width: 33.3%;" src="assets/images/home/cr/5.jpg" alt="cr-3">
      </div>
    </div>
  </div>

  <div class="awards_final_category">
    <div class="table-responsive ">
      <table class="table table-primary w-100">
        <thead class="thead-dark">
        <tr class="w-100">
          <th scope="col">Categorias</th>
          <th class="text-center bottom-bar" scope="col">A</th>
          <th class="text-center bottom-bar" scope="col">B</th>
          <th class="text-center bottom-bar" scope="col">C</th>
          <th class="text-center bottom-bar" scope="col">D</th>
          <th class="text-center bottom-bar" scope="col"><strong>Total</strong></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Quantidade de concessionárias premiadas</td>
          <td class="text-center">3</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">9</td>
        </tr>
        <tr>
          <td>Quantidade de gerentes premiados por concessionária</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">8</td>
        </tr>
        <tr>
          <td>Quantidade de funcionários premiados por concessionária</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">2</td>
          <td class="text-center">8</td>
        </tr>
        <tr>
          <td>Total de premiados por concessionária</td>
          <td class="text-center">4</td>
          <td class="text-center">4</td>
          <td class="text-center">4</td>
          <td class="text-center">4</td>
          <td class="text-center">16</td>
        </tr>
        <tr>
          <td>Total de premiados por categoria</td>
          <td class="text-center">12</td>
          <td class="text-center">8</td>
          <td class="text-center">8</td>
          <td class="text-center">8</td>
          <td class="text-center">36</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</section>

<section id="faq" class="faq bg-white">
  <div class="column w-100">
    <span class="faq_title"><strong>Perguntas Frequentes</strong></span>
    <div class="faq_row">
      <div class="faq_row-item">
        <img src="assets/images/faq/icons-item.png" alt="item">
        <span>1</span>
      </div>
      <div class="faq_row-description">
        <div class="faq_row-description-title">
          <span>Qual o período da campanha?</span>
        </div>
        <div class="faq_row-description-body">
          <span>Outubro de 2024 a Agosto de 2025</span>
        </div>
      </div>
    </div>
    <div class="faq_row">
      <div class="faq_row-item">
        <img src="assets/images/faq/icons-item.png" alt="item">
        <span>2</span>
      </div>
      <div class="faq_row-description">
        <div class="faq_row-description-title">
          <span>Quem participa?</span>
        </div>
        <div class="faq_row-description-body">
          <span>Todas as Concessionárias Volkswagen Automóveis com concessão plena, <br> e PATs que tenham sido nomeados até 30/09/2024. </span>
        </div>
      </div>
    </div>
    <div class="faq_row">
      <div class="faq_row-item">
        <img src="assets/images/faq/icons-item.png" alt="item">
        <span>3</span>
      </div>
      <div class="faq_row-description">
        <div class="faq_row-description-title">
          <span>Quais são as categorias?</span>
        </div>
        <div class="faq_row-description-body">
          <span>As concessionárias concorrerão em 4 categorias, no âmbito nacional, <br> de acordo com o seu objetivo de compras validado no PAC Pós-Vendas 2024/2025 (Potencial Anual do Concessionário).</span>
        </div>
      </div>
    </div>
    <div class="faq_row">
      <div class="faq_row-item">
        <img src="assets/images/faq/icons-item.png" alt="item">
        <span>4</span>
      </div>
      <div class="faq_row-description">
        <div class="faq_row-description-title">
          <span>Quais são os critérios de avaliação?</span>
        </div>
        <div class="faq_row-description-body">
          <span>Pontuação Mensal - Itens regulares</span>
          <div class="table-responsive">
            <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td valign="top"><p><strong>ITENS DE AVALIAÇÃO </strong></p></td>
              <td valign="top"><p><strong>PERÍODO DE AVALIAÇÃO </strong></p></td>
              <td valign="top"><p><strong>PONTUAÇÃO MÁXIMA POSSÍVEL NO MÊS </strong></p></td>
            </tr>
            <tr>
              <td valign="top"><p>1                     Performance de compras de Peças </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>300 </p></td>
            </tr>
            <tr>
              <td valign="top"><p>2                 Performance de compras de Acessórios </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>150 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>3                Evolução de Performance (P.P) em Peças </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>300 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>4            Evolução de Performance (P.P) em Acessórios </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>5                                          Autopart </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>6                     CEM: Objetivo Concessionária (Q6) </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>300 </p></td>
            </tr>
            <tr>
              <td valign="top"><p>7                                Agendamento Online </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>150 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>8                                  Revisão Planejada </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>150 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>9                                Cadência de Pedidos </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>10                           Reputação Lojas On-Line </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100</p></td>

            </tr>
            <tr>
              <td valign="top"><p>11                                   Linha Economy </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>12                                 Maxi Performance </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>13                                          Brazzo </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>14                                       Interozone </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>15                                           Wurth </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>16                                          Tirreno </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img class="faq_big-logo" src="assets/images/faq/big-triangle.png" alt="big-triangle">
</section>
