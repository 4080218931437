import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {EventBusService} from '../../service/event-bus.service';
import {UserService} from '../../service/user.service';
import {trigger, state, style, animate, transition} from '@angular/animations';

declare var $: any;
declare var simpleParallax: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      state('start', style({
        transform: 'translateY(0)'
      })),
      state('end', style({
        transform: 'translateY({{ yOffset }}px)'
      }), {params: {yOffset: '100'}}),
      transition('start => end', animate('500ms ease-in-out'))
    ])
  ]
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('window:resize', [])

  instance: any;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    variableWidth: true,
  };

  scrollState = '';

  constructor(public router: Router,
              private renderer: Renderer2,
              private el: ElementRef,
              public userService: UserService) {
    super(router);
  }

  ngOnDestroy() {
    $('.bg-montain').css('background-color', 'initial');
    this.initializeParallax();
  }

  ngAfterViewInit(): void {
    this.restartVideos();
  }

  scrollDown() {
    const element = this.el.nativeElement.querySelector('#yourTargetElementId'); // Replace with your actual element ID
    const yOffset = 100; // Adjust the scroll distance as needed

    if (element) {
      this.renderer.setStyle(document.documentElement, 'scrollBehavior', 'smooth');
      this.scrollState = 'end';
      setTimeout(() => {
        this.renderer.setStyle(document.documentElement, 'scrollBehavior', 'auto');
        this.scrollState = 'start';
        this.renderer.setProperty(document.documentElement, 'scrollTop', element.offsetTop - yOffset);
      }, 100); // Adjust the timeout to match the animation duration
    }
  }

  restartVideos(): void {
    const videos = document.querySelectorAll<HTMLVideoElement>('video');
    videos.forEach(video => {
      video.pause();
      video.currentTime = 0;
      video.play().catch(error => {
        console.error('Erro ao iniciar o vídeo:', error);
      });
    });
  }

  initializeParallax(): void {
    setTimeout(() => {
      this.toggleLoader(false);
      const imageElements = document.querySelectorAll<HTMLImageElement>('.thumbnail');
      this.instance = new simpleParallax(imageElements, {
        delay: 0.9,
        transition: 'cubic-bezier(0,0,0,1)',
      });
    }, 50);
  }

  ngOnInit(): void {
    $('.bg-montain').css('background-color', '#fff');
    this.initializeParallax();
  }
}
