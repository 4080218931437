<section class="ranking">
  <section class="ts-background">
    <div class="ranking_header header-inside-space">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-md-6 col-12 mb-md-0">
            <h1 class="ranking_title">Ranking</h1>
            <p class=" text-white"> Confira abaixo a classificação da sua concessionária. </p>
          </div>
          <div class="col-sm-6" *ngIf="user.profile !== 'dealership'">
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="input-material input-material-ranking">
                  <ng-multiselect-dropdown
                    name="city"
                    [data]="dnAutocompletes"
                    [placeholder]="'Pesquise por DN'"
                    [(ngModel)]="selectedItem"
                    [settings]="dropdownSettings"
                    (onFilterChange)="onFilterChange($event)"
                    (onSelect)="onItemSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="ranking_bg">
    <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
    <div class="container top-40">
      <div class="row" style="gap: 80px">
        <div class="w-100 mx-auto">
          <div class="ranking_resume row no-gutters d-flex col-12 flex-wrap flex-lg-row ">
            <div class="col-sm-3 col-6 card outline shadow-sm col text-center ranking_resume_0 text-white">
              <div class="card-body d-flex align-items-center justify-content-center flex-column">
                <h5 class="card-title">{{ isNullOrUndefined(rankingUser.category) ? '-' : rankingUser.category }}</h5>
                <p class="card-text">Categoria</p>
              </div>
            </div>
            <div class="col-sm-3 col-6 card shadow-sm col bg-white text-center ranking_resume_1">
              <div class="card-body d-flex align-items-center justify-content-center flex-column">
                <h5 class="card-title">{{ rankingUser.position }}°</h5>
                <p class="card-text">Posição no ranking</p>
              </div>
            </div>
            <div
              class="col-sm-5 col-6 card position-relative outline shadow-sm col text-center ranking_resume_2 text-white">
              <span class="card-title top-30 position-absolute bg-tertiary px-3">Pontos</span>
              <div class="card-body w-100 d-flex align-items-center justify-content-between">
                <div class="col-6 card-body-col">
                  <h5 class="card-title">{{ numberWithCommas(rankingUser.month) }}</h5>
                  <p class="card-text text-capitalize">Mês atual</p>
                </div>
                <div class="col-6 card-body-col">
                  <h5 class="card-title">{{ numberWithCommas(rankingUser.accumulated) }}</h5>
                  <p class="card-text">Acumulado</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card ranking_card h-100">
            <div class="card-body">
              <h5 class="card-title">Total por Categoria</h5>
              <div class="row align-items-end justify-content-between" style="row-gap: 30px">
                <div class="col">
                  <div class="ranking_card_step">
                    <a href="javascript:void(0)" [ngClass]="{'active':step === 'month'}" style="cursor:default;">Mês</a>
                  </div>
                </div>
                <div class="col-md-3" *ngIf="step==='week'">
                  <div class="input-material input-material-white mb-0">
                    <select name="promoState" id="promoState" class="form-control" [(ngModel)]="rankingCategoryMonth"
                            (ngModelChange)="handlerRankingMonth($event)">
                      <option value="1">Janeiro</option>
                      <option value="2">Fevereiro</option>
                      <option value="3">Março</option>
                    </select>
                    <label for="promoState">Mês</label>
                  </div>
                </div>
              </div>
              <google-chart style="width: 100%"
                            [type]="chartCategory.type"
                            [data]="chartCategory.data"
                            [columns]="chartCategory.columnNames"
                            [options]="chartCategory.options">
              </google-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <article class="w-100 bg-primary header-criteria">
    <div class="container d-flex align-items-center">
      <img src="assets/images/icons-item-2.png" alt="item">
      <h2 class="ranking_article_title"><span>Critérios de avaliações</span></h2>
    </div>
  </article>
  <div class="bg-primary">
    <div class="container">
      <div class="category-bar">
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '11',
          'inactive': handlerDisableMonth('11/2024')}"
             (click)="handlerMonth('11')">
          Novembro
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('11/2024')"></svg-icon>
        </div>
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '12',
          'inactive': handlerDisableMonth('12/2024')}"
             (click)="handlerMonth('12')">
          Dezembro
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('12/2024')"></svg-icon>
        </div>
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '1',
          'inactive': handlerDisableMonth('01/2025')}"
             (click)="handlerMonth('1')">
          Janeiro
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('01/2025')"></svg-icon>
        </div>
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '2',
          'inactive': handlerDisableMonth('02/2025')}"
             (click)="handlerMonth('2')">
          Fevereiro
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('02/2025')"></svg-icon>
        </div>
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '3',
          'inactive': handlerDisableMonth('03/2025')}"
             (click)="handlerMonth('3')">
          Março
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('03/2025')"></svg-icon>
        </div>
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '4',
          'inactive': handlerDisableMonth('04/2025')}"
             (click)="handlerMonth('4')">
          Abril
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('04/2025')"></svg-icon>
        </div>
        <div class="category-bar-button inactive" [ngClass]="{'active' : categoryMonth === '5',
          'inactive': handlerDisableMonth('05/2025')}"
             (click)="handlerMonth('5')">
          Maio
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('05/2025')"></svg-icon>
        </div>
        <div class="category-bar-button" [ngClass]="{'active' : categoryMonth === '6',
          'inactive': handlerDisableMonth('06/2025')}"
             (click)="handlerMonth('6')">
          Junho
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('06/2025')"></svg-icon>
        </div>
        <div class="category-bar-button inactive" [ngClass]="{'active' : categoryMonth === '7',
          'inactive': handlerDisableMonth('07/2025')}"
             (click)="handlerMonth('7')">
          Julho
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('07/2025')"></svg-icon>
        </div>
        <div class="category-bar-button inactive" [ngClass]="{'active' : categoryMonth === '8',
          'inactive': handlerDisableMonth('08/2025')}"
             (click)="handlerMonth('8')">
          Agosto
          <svg-icon class="ml-2" src="assets/svg/cadeado.svg" *ngIf="handlerDisableMonth('08/2025')"></svg-icon>
        </div>
      </div>
    </div>
  </div>

  <div style="padding-top: 20px;padding-bottom: 30px">
    <article class="container">
      <div class="row py-4" style="row-gap: 30px">
        <div class="col-12 col-lg-8">
          <div class="card ranking_card ranking_card-height">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title mb-0">Ranking Geral</h5>
                </div>
                <div class="geral-btn ">
                  <div class="input-material w-auto mx-4 mb-2" *ngIf="user.profile === 'master'">
                    <select name="dropCategory" id="dropCategory" class="input-material-small"
                            [(ngModel)]="rankingAllCategorie" (ngModelChange)="handlerMonth(categoryMonth)">
                      <option value="">Selecione a categoria</option>
                      <option value="A">Categoria A</option>
                      <option value="B">Categoria B</option>
                      <option value="C">Categoria C</option>
                      <option value="D">Categoria D</option>
                    </select>
                  </div>
                  <a href="{{urlRankingExcel}}" target="_blank" class="btn btn-sm btn-primary outline py-2 mb-2">Exportar
                    Excel</a>
                </div>
              </div>
              <div class="row">
                <div class="col-12 ">
                  <div class="table-responsive">
                    <table class="table table-half">
                      <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Concessionária</th>
                        <th class="text-center" scope="col">Acumulado</th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container *ngFor="let x of rankingAll; let i = index;">
                        <tr class="ranking_card_item" *ngIf="i < 5">
                          <td class="ranking_card_item-big"><strong>{{ x.position }}º</strong></td>
                          <td>{{ x.name }}</td>
                          <td class="text-center"><strong>{{ numberWithCommas(x.accumulated) }}</strong></td>
                        </tr>
                      </ng-container>
                      <tr class="ranking_card_item" *ngIf="this.isNullOrUndefined(rankingAll)">
                        <td colspan="4" class="text-center py-4"><strong>Nenhum registro encontrado</strong></td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="table table-full">
                      <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Concessionária</th>
                        <th class="text-center" scope="col">Acumulado</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="ranking_card_item" *ngFor="let x of rankingAll;">
                        <td class="ranking_card_item-big"><strong>{{ x.position }}º</strong></td>
                        <td>{{ x.name }}</td>
                        <td class="text-center"><strong>{{ numberWithCommas(x.accumulated) }}</strong></td>
                      </tr>
                      <tr class="ranking_card_item" *ngIf="this.isNullOrUndefined(rankingAll)">
                        <td colspan="4" class="text-center py-4"><strong>Nenhum registro encontrado</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="ranking_resume gap-30 flex-column ranking_resume-secondary no-gutters pb-4">
            <span class="text-primary ranking_resume_title"><strong>Meus Números</strong></span>
            <div class="col-12 mb-4 card outline tertiary shadow-sm col text-center ranking_resume_0 text-white">
              <div class="card-body d-flex align-items-center justify-content-center flex-column">
                <h5 class="card-title">{{ rankingUserMonth.position }}</h5>
                <p class="card-text">Posição no ranking</p>
              </div>
            </div>
            <div
              class="col-12 card position-relative outline primary-outline shadow-sm col text-center ranking_resume_2 text-white">
              <span class="card-title top-30 position-absolute bg-white px-3">Pontos</span>
              <div class="card-body gap-30 w-100 flex-column d-flex align-items-center justify-content-between">
                <div class="col-6 card-body-col column-after">
                  <h5 class="card-title">{{ numberWithCommas(rankingUser.month) }}</h5>
                  <p class="card-text text-capitalize">Mês atual</p>
                </div>
                <div class="col-6 card-body-col">
                  <h5 class="card-title">{{ numberWithCommas(rankingUserMonth.accumulated) }}</h5>
                  <p class="card-text">Acumulado</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
    <div class="w-100 bg-gray-light" style="min-height: 80dvh">
      <div class="container" style="padding: 52px 0 0">
        <div class="card bg-transparent ranking_card">
          <div class="card-body">
            <h5 class="card-title mb-0">Pontuação Mensal</h5>
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-left">Itens de avaliação</th>
                  <th class="text-center smalltd">Pontuação máxima possível no mês</th>
                  <th class="text-center smalltd last-cell text-white rounded-top">Seus pontos do mês</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="listPartnerPoints.length === 0">
                  <td colspan="3"><p class="py-5 text-center">Nenhum registro encontrado</p></td>
                </tr>
                <tr *ngFor="let x of listPartnerPoints; let i = index">
                  <td><span class="bold-and-bigger pr-2">{{ x.position }}º</span> {{ x.description }}</td>
                  <td class="text-center">{{ x.point_max }}</td>
                  <td class="text-center last-cell text-white">{{ numberWithCommas(x.point) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer bg-transparent">
            <div class="row">
              <div class="col text-center">
                <span>Total mensal</span>
              </div>
              <div class="col-3 text-center">
                <span>{{ numberWithCommas(countSum) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container title-line">
      <h1><strong>Resultado por <strong class="text-secondary">Linha</strong></strong></h1>
    </div>
    <div class="row pt-4">
      <div class="container d-flex justify-content-between flex-column flex-lg-row">
        <div class="col-lg-8 d-flex flex-wrap gap-30">
          <!--<div class="cards_for_line">
            <div class="cards_for_line_icon">200</div>
            <div class="cards_for_line_footer">
              <img src="assets/images/logo-ranking-petroplus.png" alt="logo">
            </div>
          </div>-->
          <div class="cards_for_line">
            <div class="cards_for_line_icon">50</div>
            <div class="cards_for_line_footer">
              <img src="assets/images/logo-ranking-brazzo.png" alt="logo">
            </div>
          </div>
          <div class="cards_for_line">
            <div class="cards_for_line_icon">200</div>
            <div class="cards_for_line_footer">
              <img src="assets/images/logo-ranking-maxi.png" alt="logo">
            </div>
          </div>
          <!--<div class="cards_for_line">
            <div class="cards_for_line_icon">200</div>
            <div class="cards_for_line_footer">
              <img src="assets/images/logo-ranking-bosch.png" alt="logo">
            </div>
          </div>-->
          <div class="cards_for_line">
            <div class="cards_for_line_icon">50</div>
            <div class="cards_for_line_footer">
              <img src="assets/images/logo-ranking-wurth.png" alt="logo">
            </div>
          </div>
          <div class="cards_for_line">
            <div class="cards_for_line_icon">200</div>
            <div class="cards_for_line_footer">
              <img src="assets/images/logo-ranking-tirreno.png" alt="logo">
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="card-pa">
            <div class="card-header">P&A Grupo</div>
            <div class="card-content">
              <div class="card-section">
                <span class="label">Mensal</span>
                <div class="values">
                  <span class="value">14752</span>
                  <span class="subtitle">Objetivo</span>
                  <span class="value">11471</span>
                  <span class="subtitle">Realizado</span>
                </div>
              </div>
              <hr />
              <div class="card-section">
                <span class="label">Total</span>
                <div class="values">
                  <span class="value">14752</span>
                  <span class="subtitle">Objetivo</span>
                  <span class="value">11471</span>
                  <span class="subtitle">Realizado</span>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
<!--      <div class="col-md-12 text-center py-4" *ngIf="this.isNullOrUndefined(rankingCategoryDetails)">-->
<!--        <strong>Nenhum registro encontrado</strong>-->
<!--      </div>-->
<!--      <div class="col-md-4" *ngFor="let x of rankingCategoryDetails;let i = index">-->
<!--        <div class="category-col">-->
<!--          <div class="category-card" [ngStyle]="{'z-index':(rankingCategoryDetails.length - i) * 10}">-->
<!--            <div class="category-card-top">-->
<!--              <h4>{{ x.category }}</h4>-->
<!--              <svg-icon src="assets/svg/info.svg"></svg-icon>-->
<!--            </div>-->
<!--            <div class="category-card-mid">-->
<!--              <div class="category-card-mid-col" *ngIf="!isNullOrUndefined(x.objectiveLabel)">-->
<!--                <span>{{ x.objectiveLabel }}</span>-->
<!--                <p>{{ x.objective | currency: 'BRL': ' ' }}</p>-->
<!--              </div>-->
<!--              <div class="category-card-mid-col" *ngIf="!isNullOrUndefined(x.rankingLabel)">-->
<!--                <span>{{ x.rankingLabel }}</span>-->
<!--                <b>{{ x.ranking | currency: 'BRL': ' ' }}</b>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="category-card-bottom" [ngClass]="{'hide-info' : !x.isPercentage}">-->
<!--              <div class="bar">-->
<!--                <div class="bar-value" [ngStyle]="{'width': x.percentage + '%'}">-->
<!--                  <span class="bar-value-point">{{ x.points }}pt</span>-->
<!--                  <span class="bar-value-porc">{{ x.percentage }}%</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="category-card-percent" [ngStyle]="{'z-index':(rankingCategoryDetails.length - i) * 10 - 5}"-->
<!--               *ngIf="x.category !== 'Mídia Cooperada' && x.category !== 'Varejo em Foco' && x.category !== 'Evolução'">-->
<!--            <ng-container [ngSwitch]="x.category">-->
<!--              <ng-container *ngSwitchCase="'Agendamento Online'" [ngTemplateOutlet]="agendamentoonline">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Autopart'" [ngTemplateOutlet]="autopart">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Cadência Pedidos Peças'" [ngTemplateOutlet]="cadenciapedidospecas">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'CEM Colocado Q6'" [ngTemplateOutlet]="cemcolocadoq6">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Compra de Acessórios'" [ngTemplateOutlet]="compraacessorios">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Compra de Peças'" [ngTemplateOutlet]="performancecomprapeca">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Compra de Peças & Acessórios'"-->
<!--                            [ngTemplateOutlet]="performancecomprapecaeacessorio"></ng-container>-->

<!--              <ng-container *ngSwitchCase="'Evolução de Acessórios'"-->
<!--                            [ngTemplateOutlet]="accessorios"></ng-container>-->

<!--              <ng-container *ngSwitchCase="'Grupo Óleo'" [ngTemplateOutlet]="grupooleo">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Interzone'" [ngTemplateOutlet]="interzone">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Linha Economy'" [ngTemplateOutlet]="linhaeconomy">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Óleo'" [ngTemplateOutlet]="oleo">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Tirreno'" [ngTemplateOutlet]="tirreno"></ng-container>-->
<!--              <ng-container *ngSwitchCase="'Variação Revisão Planejada'"-->
<!--                            [ngTemplateOutlet]="variacaorevisaoplanejada"></ng-container>-->

<!--              <ng-container *ngSwitchCase="'Wurth'" [ngTemplateOutlet]="wurth">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Bosch'" [ngTemplateOutlet]="bosch">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Brazzo'" [ngTemplateOutlet]="brazzo">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Evolução'" [ngTemplateOutlet]="evolucao">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Evolução de Peças'" [ngTemplateOutlet]="evopecas">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Óleo Maxi Performance (Grupo)'" [ngTemplateOutlet]="oleoMaxiPerformance">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'STP'" [ngTemplateOutlet]="stp">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Indice Pós-Vendas'" [ngTemplateOutlet]="posvendas">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Petroplus STP'" [ngTemplateOutlet]="petrostp">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Pontuação Geral - Programa Autonomia 2.0'" [ngTemplateOutlet]="autonomia">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Programa Autonomia 2.0 - 1º Tri.'" [ngTemplateOutlet]="autonomia">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchCase="'Programa Autonomia 2.0 - 2º Tri.'" [ngTemplateOutlet]="autonomia">-->
<!--              </ng-container>-->

<!--              <ng-container *ngSwitchDefault [ngTemplateOutlet]="empty"></ng-container>-->
<!--            </ng-container>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <article class="container" *ngIf="!this.isNullOrUndefined(rankingLine)">
      <h3 class="ranking_article_title_secondary mt-4 mb-5"><span>RESULTADO POR LINHA</span></h3>
      <div class="row justify-content-between" style="row-gap: 30px">
        <ng-container *ngFor="let x of rankingLine">
          <div class="col-sm" *ngIf="handlerNamePartners(x.name,0) !== 'pa'">
            <div class="partner-card">
              <div class="partner-card-top">
                <img src="assets/images/{{handlerImagePartners(x.name)}}" alt="{{x.name}}">
              </div>
              <div class="partner-card-number"><h4>{{ numberWithCommas(x.value) }}</h4></div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="result-card">
        <div class="pa-title">
          <h4> P&A Grupo </h4>
        </div>
        <div class="d-flex align-items-center justify-content-between w-100 flex-wrap">
          <div class="monthly-card">
            <p>Mensal</p>
            <div class="info">
              <div class="info-col">
                <h4>{{ numberWithCommas(findRankingLine(rankingLine, 'pa-month-objective')) }}</h4>
                <small>Objetivo</small>
              </div>
              <div class="info-col">
                <h4>{{ numberWithCommas(findRankingLine(rankingLine, 'pa-month-accumuled')) }}</h4>
                <small>Colocado</small>
              </div>
            </div>
          </div>
          <div class="divider"></div>
          <div class="monthly-card">
            <p>Mensal</p>
            <div class="info">
              <div class="info-col">
                <h4>{{ numberWithCommas(findRankingLine(rankingLine, 'pa-total-objective')) }}</h4>
                <small>Objetivo</small>
              </div>
              <div class="info-col">
                <h4>{{ numberWithCommas(findRankingLine(rankingLine, 'pa-total-accumuled')) }}</h4>
                <small>Colocado</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>

</section>


<ng-template #agendamentoonline>
  <table>
    <tr>
      <th>VARIAÇÃO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 10% a 15% das passagens</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>De 16% a 20% das passagens</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>Acima de 20% das passagens</td>
      <td class="text-center"><b>150</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #autopart>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 50% a 69,9%</td>
      <td class="text-center"><b>30</b></td>
    </tr>
    <tr>
      <td>De 70% a 79,9%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>Acima de 80%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #cadenciapedidospecas>
  <table>
    <tr>
      <th>VARIAÇÃO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>50% até 10 dias úteis do fechamento</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>80% até 3 dias úteis do fechamento</td>
      <td class="text-center"><b>100</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #cemcolocadoq6>
  <table>
    <tr>
      <th>NOTA (M.M TRIM)</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>8,000 a 8,499</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>8,500 a 8,999</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>9,000 a 9,499</td>
      <td class="text-center"><b>250</b></td>
    </tr>
    <tr>
      <td>Acima 9,500</td>
      <td class="text-center"><b>300</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #compraacessorios>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0,00% a 99,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 104,99%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>De 105,00% a 109,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>125</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>150</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #performancecomprapeca>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0,00% a 99,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 104,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 105,00% a 109,99%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>250</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>300</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #performancecomprapecaeacessorio>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0,00% a 99,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 104,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 105,00% a 109,99%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>250</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>300</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #grupooleo>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #interzone>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #linhaeconomy>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 100% a 109,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 110% a 119,99%</td>
      <td class="text-center"><b>150</b></td>
    </tr>
    <tr>
      <td>Acima de 120%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #oleo>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #tirreno>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #variacaorevisaoplanejada>
  <table>
    <tr>
      <th>VARIAÇÃO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0 a 14,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 15% a 19,99%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>Acima de 20%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #wurth>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>140</b></td>
    </tr>
    <tr>
      <td>De 100,00% acima</td>
      <td class="text-center"><b>180</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #bosch>
  <table class="text-center">
    <tr>
      <th><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th><strong>PONTUAÇÃO</strong></th>
    </tr>
    <tr>
      <td>0% a 89,99%</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>90% a 109,99%</td>
      <td class="text-center">80</td>
    </tr>
    <tr>
      <td>110,00 % a 119,99%</td>
      <td class="text-center">140</td>
    </tr>
    <tr>
      <td>Acima de 120,00%</td>
      <td class="text-center">180</td>
    </tr>
    <tr>
      <td>Bônus*</td>
      <td class="text-center">20</td>
    </tr>
  </table>
</ng-template>

<ng-template #brazzo>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>CATEGORIA</strong></th>
      <th class="text-center" valign="top"><strong>OBJETIVO MENSAL</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>100%<br/><br/>100 Pontos</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>110%<br/><br/>150 Pontos</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>120%<br/><br/>200 Pontos</strong></th>
    </tr>
    <tr>
      <td>A</td>
      <td>R$ 15.000,00</td>
    </tr>
    <tr>
      <td>B</td>
      <td>R$ 12.000,00</td>
    </tr>
    <tr>
      <td>C</td>
      <td>R$ 9.500,00</td>
    </tr>
    <tr>
      <td>D</td>
      <td>R$ 6.500,00</td>
    </tr>
  </table>
</ng-template>

<ng-template #evolucao>
  <!--<table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS (até 99,99% de performance)</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS (acima de 100% de performance)</strong></th>
    </tr>
    <tr>
      <td>De 0,00 p.p a 1,99 p.p.</td>
      <td class="text-center">0</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 2,0 p.p a 3,99 p.p</td>
      <td class="text-center">25</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>De 4,0 p.p a 5,99 p.p</td>
      <td class="text-center">50</td>
      <td class="text-center">200</td>
    </tr>
    <tr>
      <td>De 6,0 p.p a 8,99 p.p</td>
      <td class="text-center">75</td>
      <td class="text-center">250</td>
    </tr>
    <tr>
      <td>A partir de 9,00 p.p.</td>
      <td class="text-center">100</td>
      <td class="text-center">300</td>
    </tr>

  </table>-->
</ng-template>

<ng-template #oleoMaxiPerformance>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO</strong></th>
    </tr>
    <tr>
      <td>De 0% a 89,99%</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 90% a 99,99%</td>
      <td class="text-center">80</td>
    </tr>
    <tr>
      <td>De 100% a 109,99%</td>
      <td class="text-center">120</td>
    </tr>
    <tr>
      <td>De 110% a 119,99%</td>
      <td class="text-center">160</td>
    </tr>
    <tr>
      <td>Acima de 120%</td>
      <td class="text-center">200</td>
    </tr>
  </table>
</ng-template>
<ng-template #stp>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO</strong></th>
    </tr>
    <tr>
      <td>De 0% a 84,99%</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 85% a 89,99%</td>
      <td class="text-center">40</td>
    </tr>
    <tr>
      <td>De 90% a 94,99%</td>
      <td class="text-center">80</td>
    </tr>
    <tr>
      <td>De 95% a 99,99%</td>
      <td class="text-center">110</td>
    </tr>
    <tr>
      <td>100% Acima</td>
      <td class="text-center">150</td>
    </tr>
  </table>
</ng-template>

<ng-template #accessorios>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS <br>(até 99,99% de performance)</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO <br>(até 100,00% de performance)</strong></th>
    </tr>
    <tr>
      <td>De 0,00 p.p a 1,99 p.p.</td>
      <td class="text-center">0</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 2,0 p.p a 3,99 p.p.</td>
      <td class="text-center">12,5</td>
      <td class="text-center">50</td>
    </tr>
    <tr>
      <td>De 4,0 p.p a 5,99 p.p.</td>
      <td class="text-center">25</td>
      <td class="text-center">75</td>
    </tr>
    <tr>
      <td>De 6,0 p.p a 8,99 p.p.</td>
      <td class="text-center">37,5</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>A partir de 9,00 p.p.</td>
      <td class="text-center">50</td>
      <td class="text-center">200</td>
    </tr>

  </table>
</ng-template>

<ng-template #evopecas>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS <br>(até 99,99% de performance)</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO <br>(até 100,00% de performance)</strong></th>
    </tr>
    <tr>
      <td>De 0,00 p.p a 1,99 p.p.</td>
      <td class="text-center">0</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 2,0 p.p a 3,99 p.p.</td>
      <td class="text-center">25</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>De 4,0 p.p a 5,99 p.p.</td>
      <td class="text-center">50</td>
      <td class="text-center">200</td>
    </tr>
    <tr>
      <td>De 6,0 p.p a 8,99 p.p.</td>
      <td class="text-center">75</td>
      <td class="text-center">250</td>
    </tr>
    <tr>
      <td>A partir de 9,00 p.p.</td>
      <td class="text-center">100</td>
      <td class="text-center">300</td>
    </tr>

  </table>
</ng-template>

<ng-template #posvendas>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>ÍNDICE</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS</strong></th>
    </tr>
    <tr>
      <td>≥80,00%</td>
      <td class="text-center">600</td>

    </tr>
    <tr>
      <td>70,00% a 79,99%</td>
      <td class="text-center">300</td>

    </tr>
    <tr>
      <td>60,00% a 69,99%</td>
      <td class="text-center">200</td>

    </tr>
    <tr>
      <td>50,00% a 59,00%</td>
      <td class="text-center">50</td>

    </tr>
    <tr>
      <td>≤50,00%</td>
      <td class="text-center">0</td>

    </tr>


  </table>
</ng-template>

<ng-template #petrostp>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTUAÇÃO</th>
    </tr>
    <tr>
      <td>0,00% a 84,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>85% a 89,99%</td>
      <td class="text-center"><b>40</b></td>
    </tr>
    <tr>
      <td>90,00% a 94,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>95% a 99,99%</td>
      <td class="text-center"><b>110</b></td>
    </tr>
    <tr>
      <td>100% Acima</td>
      <td class="text-center"><b>150</b></td>
    </tr>
    <tr>
      <td>50% do Objetivo FAT.em G - 001 - 780 - Pontuaça Adicional</td>
      <td class="text-center"><b>50</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #autonomia>
  <table>
    <tr>
      <th>PONTUAÇÂO</th>
      <th class="text-center">CLASSIFICAÇÃO</th>
    </tr>
    <tr>
      <td>500 PONTOS</td>
      <td class="text-center"><b>DNs classificados como A+ na medição trimestral do Programa Autonomia </b></td>
    </tr>
    <tr>
      <td>250 PONTOS</td>
      <td class="text-center"><b>DNs classificados como A na medição trimestral do Programa Autonomia</b></td>
    </tr>
    <tr>
      <td>125 PONTOS</td>
      <td class="text-center"><b>DNs classificados como B na medição trimestral do Programa Autonomia</b></td>
    </tr>
    <tr>
      <td>000 PONTOS</td>
      <td class="text-center"><b>DNs classificados como C na medição trimestral do Programa Autonomia</b></td>
    </tr>


  </table>
</ng-template>

<ng-template #empty>

</ng-template>
