import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {RankingService} from '../../service/ranking.service';
import {RankingUser} from '../../model/ranking/ranking-user';
import {PartnerPointsMonth, RankingAll} from '../../model/ranking/ranking-all';
import {RankingCategoryDetail} from '../../model/ranking/ranking-category-detail';
import {RankingLine} from '../../model/ranking/ranking-line';
import {RankingCategory} from '../../model/ranking/ranking-category';
import {DnAutocomplete} from '../../model/ranking/dn-autocomplete';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentInit {
  public BASE_URL = environment.base_url;
  categoryMonth = (moment().month() + 1).toString(); // '4';
  rankingCategoryMonth = (moment().month() + 1).toString(); // '4';
  step = 'month';

  dnAutocompletes: DnAutocomplete[] = [];
  selectedItem: DnAutocomplete[] = [];

  dropdownSettings: any = {};

  rankingUser: RankingUser = new RankingUser();
  rankingUserMonth: RankingUser = new RankingUser();
  rankingAll: RankingAll[] = [];
  rankingCategoryDetails: RankingCategoryDetail[] = [];
  rankingLine: RankingLine[] = [];
  rankingCategory: RankingCategory = new RankingCategory();
  user: User = new User();
  table = 'autopart';

  rankingAllCategorie = '';
  urlRankingExcel = '';

  countSum = 0;
  listPartnerPoints: PartnerPointsMonth[] = [];

  currentMonth = moment().locale('pt').format('MMMM');
  currentMonthNumber = moment().month() + 1;

  isShowDetail = false;
  itemDetail: any = null;

  constructor(public router: Router,
              public rankingService: RankingService,
              public userService: UserService) {
    super(router);
  }


  ngOnInit(): void {
    super.resizeCharts();
    this.userService.user.subscribe(data => {
      if (!this.isNullOrUndefined(data.id)) {
        this.user = data as User;
        // if (this.user.profile === 'partner') {
        //   this.router.navigate(['/']);
        // }
        // this.categoryMonth = '3';
        if (!this.isNullOrUndefined(this.user.fantasyName) && !this.isNullOrUndefined(this.user.dn)) {
          this.selectedItem = [{name: this.user.fantasyName, dn: this.user.dn}];
        }
        this.setRankingUser();
        this.setRankingAll();
        this.setRankingCategoryDetail();
        this.setRankingLine();
        this.setRankingCategory('month');
      }
    }, error => super.onError(error));

    $('#modalRankingDetail').on('hidden.bs.modal', (e) => {
      console.log('modal fechado');
    });
  }

  ngAfterContentInit(): void {
    this.dropdownSettings = {
      searchPlaceholderText: 'DN ou Nome',
      singleSelection: true,
      clearSearchFilter: true,
      allowSearchFilter: true,
      idField: 'dn',
      textField: 'name',
      closeDropDownOnSelection: true,
      allowRemoteDataSearch: true
    };
  }

  ngOnDestroy(): void {
    $('.header').removeClass('bg-primary');
    $('.bg-montain').css('background-color', 'initial');
  }

  getPointsMonth(): void {
    this.rankingService.getPointsMonth(this.selectedItem[0].dn, this.categoryMonth).subscribe(data => {
      this.listPartnerPoints = data as PartnerPointsMonth[];
      this.countSum = this.listPartnerPoints.reduce(function(accumulator, object) {
        return accumulator + object.point;
      }, 0);

    }, error => this.onError(error));
  }

  setRankingLine() {
    this.rankingService.getRankingLine(this.categoryMonth, this.selectedItem[0].dn).subscribe(data => {
      this.rankingLine = data as RankingLine[];
    }, error => this.onError(error));
  }

  setRankingCategory(step: string) {
    this.step = step;
    this.rankingService.getRankingCategory(this.step, this.rankingCategoryMonth, this.selectedItem[0].dn).subscribe(data => {
      this.rankingCategory = data as RankingCategory;
      if (this.rankingCategory?.items.length === 0) {
        this.chartCategory.columnNames = ['', ''];
        this.chartCategory.data = [['', 0]];
        return;
      }
      const arrayColumn = ['Data'];
      this.rankingCategory.labels.forEach((e) => arrayColumn.push(e));
      const arrayData = [];
      this.rankingCategory.items.forEach((e, index) => {
        const arrayItem = [];
        if (this.step === 'week') {
          arrayItem.push(moment(e.data, 'YY-MM').format('DD/MM/YYYY'));
        } else {
          arrayItem.push(moment(e.data, 'YY-MM').locale('pt-br').format('MMMM'));
        }
        e.value.forEach((item) => arrayItem.push(item));
        arrayData.push(arrayItem);
      });
      this.chartCategory.columnNames = arrayColumn;
      this.chartCategory.data = arrayData;
    }, error => this.onError(error));
  }

  setRankingCategoryDetail() {
    this.rankingService.getRankingCategoryDetail(this.categoryMonth, this.selectedItem[0].dn).subscribe(data => {
      this.rankingCategoryDetails = data as RankingCategoryDetail[];
    }, error => this.onError(error));
  }

  setRankingUser() {
    this.rankingService.getRankingUser(this.selectedItem[0].dn).subscribe(data => {
      this.rankingUser = data as RankingUser;
    }, error => this.onError(error));
  }

  setRankingUserMonth() {
    const a = this.selectedItem[0].dn + '?month=' + this.categoryMonth;
    this.rankingService.getRankingUser(a).subscribe(data => {
      this.rankingUserMonth = data as RankingUser;
    }, error => this.onError(error));
  }

  setRankingAll() {
    this.setRankingUserMonth();
    this.getPointsMonth();
    const categoryMonthUrl = (this.user.profile === 'master' && !this.isNullOrUndefined(this.rankingAllCategorie)) ? this.categoryMonth + '?category=' + this.rankingAllCategorie : this.categoryMonth;
    this.rankingService.getRankingAll(this.selectedItem[0].dn, categoryMonthUrl).subscribe(data => {
      this.rankingAll = data as RankingAll[];
      this.setExcelUrl();
    }, error => this.onError(error));
  }

  handlerImagePartners(name: string) {
    switch (name) {
      case 'maxi':
        return 'maxiwhite.png';
      case 'economy':
        return 'economywhite.png';
      case 'tirreno':
        return 'tirrenowhite.png';
      case 'wurth':
        return 'wurthwhite.png';
      case 'interozone':
        return 'interozone.png';
      case 'bosch':
        return 'boschwhite.png';
      case 'brazzo':
        return 'brazzowhite.png';
      case 'pa-month-accumuled':
        return 'pa-month-accumuled.png';
      case 'pa-month-objective':
        return 'pa-month-objective.png';
      case 'stp':
        return 'stpwhite.png';
      default:
        return 'tirrenowhite.png';
    }
  }

  handlerNamePartners(name: string, position: number) {
    return name.split('-')[position];
  }

  findRankingLine(rankingLine: RankingLine[], name: string) {
    return rankingLine.find(e => e.name === name)?.value;
  }

  onItemSelect(event: DnAutocomplete) {
    this.selectedItem = [event];
    this.setRankingUser();
    this.setRankingCategory('month');
    this.handlerMonth((moment().month() + 1).toString());
  }

  onFilterChange(event: any) {
    this.setComplete(event);
  }

  setComplete(keyword: string) {
    this.rankingService.getAutoComplete(keyword).subscribe(data => {
      this.dnAutocompletes = data as DnAutocomplete[];
      this.dnAutocompletes.forEach(e => e.name = e.dn + ' - ' + e.name);
    }, error => this.onError(error));
  }

  handlerDisableMonth(mounth: string) {
    const formatDate = moment('01/' + mounth, 'DD/MM/YYYY');
    return moment().isBefore(formatDate);
  }

  handlerMonth(month: string, isYearOld = false) {
    if (Number(month) > this.currentMonthNumber && !isYearOld) {
      return;
    }

    this.categoryMonth = month;
    console.log(`Mês selecionado: ${month}`);

    this.setRankingAll();
    this.setRankingCategoryDetail();
    this.setRankingLine();
  }

  handlerRankingMonth(event: any) {
    this.rankingCategoryMonth = event;
    this.setRankingCategory('week');
  }

  setExcelUrl() {
    const e = this.isNullOrUndefined(this.rankingAllCategorie) ? '' : '&category=' + this.rankingAllCategorie;
    const a = `${this.selectedItem[0].dn}/${this.categoryMonth}?token=${localStorage.getItem('token') + e}`;
    this.urlRankingExcel = `${this.BASE_URL}/ranking/all-excel/${a}`;
  }

  onShowDetail(i: number) {
    this.itemDetail = this.rankingCategoryDetails[i];
    this.isShowDetail = true;
    console.log(this.itemDetail);
    setTimeout(() => {
      $('#modalRankingDetail').modal('show');
    });
  }
}
