<div style="min-height: 100vh">
  <div class="partner">
    <div class="container">

      <div class="partner-header">
        <div class="partner-logo-container">
          <img style="object-fit: cover;" src="assets/images/partner/{{provider}}.png"
               alt="{{provider}}"/>
        </div>
        <div class="partner-header-title">
          <h1>Painel Administrativo</h1>
          <h3>Maxi Performance</h3>
        </div>
        <!-- <div class="container">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="input-material white w-30 mx-4 mb-0" *ngIf="model.profile === 'master'">
                      <select name="dropPartner" id="dropPartner" [(ngModel)]="provider" (ngModelChange)="getDashboard($event)">
                        <option value="stp">STP</option>
                        <option value="maxi">Maxi</option>
                        <option value="interozone">Interozone</option>
                        <option value="tirreno">Tirreno</option>
                        <option value="wurth">Wurth</option>
                        <option value="bosch">Bosch</option>
                        <option value="brazzo">Brazzo</option>
                      </select>
                      <label for="dropPartner">Selecione</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

    </div>
  </div>
  <img class="standard-bg" src="assets/images/partner/bg-standard.png" alt="standard-bg">
  <div class="container" style="">
    <div class="row pb-5" style="row-gap: 30px">
      <div class="col-sm-7">

        <div class="card shadow-sm ranking_card">
          <div class="card-body">
            <h5 class="card-title">Acumulado total</h5>
            <google-chart style="width: 100%"
                          [type]="chartCategory.type"
                          [data]="chartCategory.data"
                          [columns]="chartCategory.columnNames"
                          [options]="chartCategory.options">
            </google-chart>
          </div>
        </div>

      </div>
      <div class="col-sm-5">
        <div class="">
          <ng-container [ngSwitch]="provider">
            <ng-container *ngSwitchCase="'stp'" [ngTemplateOutlet]="stp"></ng-container>
            <ng-container *ngSwitchCase="'maxi'" [ngTemplateOutlet]="maxi"></ng-container>
            <ng-container *ngSwitchCase="'interozone'" [ngTemplateOutlet]="interozone"></ng-container>
            <ng-container *ngSwitchCase="'tirreno'" [ngTemplateOutlet]="tirreno"></ng-container>
            <ng-container *ngSwitchCase="'wurth'" [ngTemplateOutlet]="wurth"></ng-container>
            <!--<ng-container *ngSwitchCase="'bosch'" [ngTemplateOutlet]="bosch"></ng-container>-->
            <ng-container *ngSwitchCase="'brazzo'" [ngTemplateOutlet]="brazzo"></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="">
      <div class="card ranking_card pt-4">
        <div class="">
          <div class="table-filter-heading">
            <div class="container">
              <div class="py-3 mb-2">
                <div class="row align-items-center" style="row-gap: 15px;margin-left:-.25rem;margin-right:-.25rem;">
                  <div class="col px-1">
                    <h5 class="table-filter-heading-title mb-0">Ranking Geral</h5>
                  </div>
                                <div class="col-auto px-1">
                                  <button href="javascript:void('');" (click)="getExcel()" class="btn btn-export"
                                     style="text-transform: initial">Exportar Excel</button>
                                 </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="input-material">
                    <input type="text" maxlength="250" required
                           [(ngModel)]="modelRakingFilter.userDn" mask="0000" inputmode="number" id="dropDealerRakingFilter" name="formFooter1"/>
                    <label class="" for="dropDealerRakingFilter">Dn</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-material">
                    <select name="dropCategoryFilter" id="dropCategoryRakingFilter"
                            [(ngModel)]="modelRakingFilter.category">
                      <option [value]="undefined">Selecione a categoria</option>
                      <option value="A">Categoria A</option>
                      <option value="B">Categoria B</option>
                      <option value="C">Categoria C</option>
                      <option value="D">Categoria D</option>
                    </select>
                    <label class="select-label" for="dropCategoryRakingFilter">Categoria</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-material">
                    <select name="dropMonthFilter" id="dropMonthRakingFilter" [(ngModel)]="modelRakingFilter.month">
                      <option [value]="undefined">Selecione o Mês</option>
                      <option value="11">Nov / 2023</option>
                      <option value="12">Des / 2023</option>
                      <option value="1">Jan / 2024</option>
                      <option value="2">Fev / 2024</option>
                      <option value="3">Mar / 2024</option>
                      <option value="4">Abr / 2024</option>
                      <option value="5">Mai / 2024</option>
                      <option value="5">Jun / 2024</option>
                      <option value="5">Jul / 2024</option>
                      <option value="6">Ago / 2024</option>
                      <!--                    <option value="12">12 - Dez</option>-->
                    </select>
                    <label class="select-label" for="dropMonthRakingFilter">Mês</label>
                  </div>
                </div>
                <div class="col-sm-2">
                  <a class="btn btn-block btn-lg btn-primary btn-filter" style="text-transform: initial"
                     (click)="currentPage = 1; actionRakingSearch()">
                    <small>Buscar</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row py-4">
            <div class="container">
              <div class="ranking-tables-wrapper">
                <div class="">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th class="text-center" scope="col"></th>
                      <th scope="col">Concessionária</th>
                      <th class="text-center" scope="col">Acumulado</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="modelRankingResult.results.length === 0">
                      <td colspan="6">
                        <p class="py-5 my-5 text-center">Nenhum registro encontrado.</p>
                      </td>
                    </tr>
                    <tr class="ranking_card_item" *ngFor="let x of list1 |
                    paginate: {
                     id:'raking',
                     itemsPerPage: modelRankingResult.pageSize,
                     currentPage: modelRankingResult.currentPage,
                     totalItems: modelRankingResult.totalCount}">
                      <td class="ranking_card_item-big"><strong>{{x.position}}º</strong></td>
                      <td>{{x.dealer}}</td>
                      <td class="text-center">{{x.accumulated }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
                <div class="">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Concessionária</th>
                      <th class="text-center" scope="col">Acumulado</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="modelRankingResult.results.length === 0">
                      <td colspan="6">
                        <p class="py-5 my-5 text-center">Nenhum registro encontrado.</p>
                      </td>
                    </tr>
                    <tr class="ranking_card_item" *ngFor="let x of list2 |
                    paginate: {
                     id:'raking',
                     itemsPerPage: modelRankingResult.pageSize,
                     currentPage: modelRankingResult.currentPage,
                     totalItems: modelRankingResult.totalCount}">
                      <td class="ranking_card_item-big"><strong>{{x.position}}º</strong></td>
                      <td>{{x.dealer}}</td>
                      <td class="text-center">{{numberWithCommas(x.accumulated)}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="text-center">
            <pagination-controls
              [id]="'raking'"
              [previousLabel]="labels.previousLabel"
              [nextLabel]="labels.nextLabel"
              [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
              [screenReaderPageLabel]="labels.screenReaderPageLabel"
              [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
              (pageChange)="pageRakingChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="card ranking_card pt-4">
        <div class="">
          <div class="table-filter-heading">
            <div class="container">
              <div class="py-3 mb-2">
                <div class="row align-items-center" style="row-gap: 15px;margin-left:-.25rem;margin-right:-.25rem;">
                  <div class="col px-1">
                    <h5 class="table-filter-heading-title mb-0 mb-0">Lista de Concessionárias</h5>
                  </div>
                  <div class="col-auto px-1">
                    <button (click)="getExcel()" class="btn btn-export"
                       style="text-transform: initial">Exportar Excel</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="input-material">
                    <input type="text" maxlength="250" required
                           [(ngModel)]="modelFilter.dn" mask="0000" inputmode="number" id="dropDealerFilter" name="formFooter1"/>
                    <label class="" for="dropDealerFilter">Dn</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-material">
                    <select name="dropCategoryFilter" id="dropCategoryFilter" [(ngModel)]="modelFilter.category">
                      <option [value]="undefined">Selecione a categoria</option>
                      <option value="A">Categoria A</option>
                      <option value="B">Categoria B</option>
                      <option value="C">Categoria C</option>
                      <option value="D">Categoria D</option>
                    </select>
                    <label class="select-label" for="dropCategoryFilter">Categoria</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="input-material">
                    <select name="dropMonthFilter" id="dropMonthFilter" [(ngModel)]="modelFilter.month">
                      <option [value]="undefined">Selecione o Mês</option>
                      <option value="11">Nov / 2023</option>
                      <option value="12">Des / 2023</option>
                      <option value="1">Jan / 2024</option>
                      <option value="2">Fev / 2024</option>
                      <option value="3">Mar / 2024</option>
                      <option value="4">Abr / 2024</option>
                      <option value="5">Mai / 2024</option>
                      <option value="5">Jun / 2024</option>
                      <option value="5">Jul / 2024</option>
                      <option value="6">Ago / 2024</option>
                      <!--                    <option value="12">12 - Dez</option>-->
                    </select>
                    <label class="select-label" for="dropMonthFilter">Mês</label>
                  </div>
                </div>
                <div class="col-sm-2">
                  <a class="btn btn-block btn-lg btn-primary btn-filter" style="text-transform: initial"
                     (click)="currentPage = 1; actionSearch()">
                    <small>Buscar</small>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row py-4">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th class="text-center" scope="col">Concessionária DN</th>
                    <th class="text-center" scope="col">Categoria</th>
                    <th class="text-center" scope="col">Mês</th>
                    <th class="text-center" scope="col">Objetivo</th>
                    <th class="text-center" scope="col">Resultados</th>
                    <th class="text-center" scope="col">Pontos %</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngIf="modelResult.results.length === 0">
                    <td colspan="6">
                      <p class="py-5 my-5 text-center">Nenhum registro encontrado.</p>
                    </td>
                  </tr>
                  <tr class="ranking_card_item" *ngFor="let x of modelResult.results |
                    paginate: {
                     itemsPerPage: modelResult.pageSize,
                     currentPage: modelResult.currentPage,
                     totalItems: modelResult.totalCount}">
                    <td class="text-center">{{x.dn}}</td>
                    <td class="text-center">{{x.category}}</td>
                    <td class="text-center">{{x.date | date: 'M'}}</td>
                    <td class="text-center">{{x.objective}}</td>
                    <td class="text-center">{{x.position}}</td>
                    <td class="text-center">{{x.points}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="text-center">
            <pagination-controls
              [previousLabel]="labels.previousLabel"
              [nextLabel]="labels.nextLabel"
              [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
              [screenReaderPageLabel]="labels.screenReaderPageLabel"
              [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
              (pageChange)="pageChanged($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #stp>
  <table class="table-partners text-center">
    <thead>
    <tr>
      <td valign="top"><strong style="text-transform: uppercase">Percentual de cumprimento do objetivo</strong></td>
      <td valign="top"><strong>PONTUAÇÃO</strong></td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td valign="top">De 0,00% a 84,99%</td>
      <td valign="top">0</td>
    </tr>
    <tr>
      <td valign="top">85% a 89,99%</td>
      <td valign="top">40</td>
    </tr>
    <tr>
      <td valign="top">90% a 94,99%</td>
      <td valign="top">80</td>
    </tr>
    <tr>
      <td valign="top">95% a 99,99%</td>
      <td valign="top">110</td>
    </tr>
    <tr>
      <td valign="top">100% acima</td>
      <td valign="top">150</td>
    </tr>
    <tr>
      <td valign="top" class="bg-secondary">50% do Objetivo Fat. em G-001-780 - Pontuação Adicional</td>
      <td valign="top" class="bg-secondary">50</td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #maxi>
  <table class="table-partners">
    <thead>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>De 0% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #interozone>
  <table class="table-partners text-center">
    <thead>
    <tr>
      <th align="center" valign="top"><strong>A</strong></th>
      <th align="center" valign="top"><p><strong>B</strong></th>
      <th align="center" valign="top"><p><strong>C</strong></th>
      <th align="center" valign="top"><p><strong>D</strong></th>
      <th align="center" valign="top" rowspan="2"><strong>PONTUAÇÃO<br>MENSAL</strong></th>
    </tr>
    <tr>
      <th colspan="4" align="center"><strong>Volume de Cartões</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td valign="top">100</td>
      <td valign="top">80</td>
      <td valign="top">60</td>
      <td valign="top"><p>40</td>
      <td valign="top">50</td>
    </tr>
    <tr>
      <td valign="top">120</td>
      <td valign="top">100</td>
      <td valign="top">80</td>
      <td valign="top"><p>60</td>
      <td valign="top">75</td>
    </tr>
    <tr>
      <td valign="top">140</td>
      <td valign="top">120</td>
      <td valign="top">100</td>
      <td valign="top"><p>80</td>
      <td valign="top">100</td>
    </tr>
    <tr>
      <td valign="top">160&gt;</td>
      <td valign="top">140&gt;</td>
      <td valign="top">120&gt;</td>
      <td valign="top"><p>100&gt;</td>
      <td valign="top">200</td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #tirreno>
  <table class="table-partners text-center">
    <thead>
    <tr>
      <td valign="top"><strong>&nbsp;</strong>
        <strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></td>
      <td valign="top"><strong>&nbsp;</strong> <strong>PONTOS</strong></td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td valign="top">0 a 79,99%</td>
      <td valign="top">0</td>
    </tr>
    <tr>
      <td valign="top">80% a 84,99%</td>
      <td valign="top">40</td>
    </tr>
    <tr>
      <td valign="top">85% a 89,99%</td>
      <td valign="top">60</td>
    </tr>
    <tr>
      <td valign="top">90% a 94,99%</td>
      <td valign="top">140</td>
    </tr>
    <tr>
      <td valign="top">95% a 99,99%</td>
      <td valign="top">160</td>
    </tr>
    <tr>
      <td valign="top">100%</td>
      <td valign="top">180</td>
    </tr>
    <tr>
      <td valign="top"><strong>Bônus*</strong><strong> </strong></td>
      <td colspan="2" valign="top"><strong>20</strong><strong> </strong></td>
    </tr>
    <tr>
      <td colspan="3" valign="top"><strong>&nbsp;</strong>
        *Para o cliente ganhar a pontuação bônus, seu pedido deverá conter obrigatóriamente: <br>
        <strong>1) Fluido para freio DOT 4LV 500 ml (B 000 750 Q2), independente do percentual da meta que
          comprar.</strong><strong> </strong></td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #wurth>
  <table class="table-partners text-center">
    <thead>
    <tr>
      <td valign="top"><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></td>
      <td valign="top"><strong>PONTUAÇÃO</strong></td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td valign="top">0 a 89,99%</td>
      <td valign="top">0</td>
    </tr>
    <tr>
      <td valign="top">90 a 99,99%</td>
      <td valign="top">140</td>
    </tr>
    <tr>
      <td valign="top">100% e acima</td>
      <td valign="top">180</td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #bosch>
  <table class="table-partners text-center">
    <thead>
    <tr>
      <th valign="top"><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th valign="top"><strong>PONTUAÇÃO</strong></th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td valign="top">0% a 89,99%</td>
      <td valign="top">0</td>
    </tr>
    <tr>
      <td valign="top">90% a 109,99%</td>
      <td valign="top">80</td>
    </tr>
    <tr>
      <td valign="top">110,00 % a 119,99%</td>
      <td valign="top">140</td>
    </tr>
    <tr>
      <td valign="top">Acima de 120,00%</td>
      <td valign="top">180</td>
    </tr>
    <tr>
      <td valign="top">Bônus*</td>
      <td valign="top">20</td>
    </tr>
    <tr>
      <td colspan="2" valign="top"><strong>&nbsp;</strong>
        (*) Para ganhar o bônus mensal de 20 pontos, a concessionária deve cumprir no mínimo 100% do objetivo BOSCH e,
        no mínimo, 10% do objetivo deve ser em produtos de lançamento de 2023 (NOVO) ou itens da linha ECONOMY.
      </td>
    </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #brazzo>
  <table class="table-partners text-center">
    <tbody>
    <tr>
      <th class="text-center" valign="top"><strong>CATEGORIA</strong></th>
      <th class="text-center" valign="top"><strong>OBJETIVO MENSAL</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>100%<br/><br/>Pontos</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>110%<br/><br/>Pontos</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>120%<br/><br/>Pontos</strong></th>
    </tr>
    <tr>
      <td>A</td>
      <td>R$ 5.000,00</td>
    </tr>
    <tr>
      <td>B</td>
      <td>R$ 4.000,00</td>
    </tr>
    <tr>
      <td>C</td>
      <td>R$ 3.000,00</td>
    </tr>
    <tr>
      <td>D</td>
      <td>R$ 2.000,00</td>
    </tr>
    </tbody>
  </table>
</ng-template>
