<div class="login" >
  <div class="login-bg-video">
    <video src="../../../assets/videos/bg-blue.mp4" muted autoplay loop playsinline></video>
    <img src="assets/images/login/bg_blue.jpg" alt="bg_blue"/>
  </div>
  <img class="login_bg_tokyo" src="assets/images/login/arara.png" alt="logo"/>
  <img class="login_bg_montain-1" src="assets/images/login/montanha-1.png" alt="logo"/>
  <img class="login_bg_beto" src="assets/images/login/montanha-2.png" alt="logo"/>
  <div class="login_content">
    <img src="assets/images/login/logo.png" width="494" height="361" alt="">
    <ng-container class="div" *ngIf="isLoginPanel">
      <div class="login_content-info">

        <fieldset class="form-primary">
          <div class="input-material input-material-label-outline">
            <input type="text" required="required" name="promoLoginCPF"
                   [(ngModel)]="model.cnpj" maxlength="20" [mask]="'00.000.000/0000-00'"
                   [dropSpecialCharacters]="false"/>
            <label>CNPJ</label>
          </div>
          <div class="input-material input-material-label-outline">
            <input type="password" required="required"
                   (keyup.enter)="onLogin()"
                   [(ngModel)]="model.password"
                   id="password"
                   name="password"
                   maxlength="50"/>
            <label>Senha</label>
            <div href="javascript:void(0)" class="btn-password-view" (click)="handlerViewPassword()">
              <svg-icon
                [src]="!handlerViewPasswordIcon() ? 'assets/svg/password-open.svg' : 'assets/svg/password-closed.svg'"></svg-icon>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end align-items-center">
            <a class="password" (click)="isLoginPanel = false">
              Esqueci minha Senha
            </a>
          </div>
          <div class="d-block my-4 px-5">
            <a href="javascript:void('');" (click)="onLogin()" class="btn btn-block btn-primary">ENTRAR</a>
          </div>
        </fieldset>
      </div>
    </ng-container>


    <ng-container *ngIf="!isLoginPanel">
      <div class="login_content-info">
        <h2 class="text-primary">Esqueci minha senha</h2>
        <fieldset class="form-primary">
          <p class="text-primary">Digite o CNPJ cadastrado.</p>
          <div class="input-material input-material-label-outline">
            <input type="text" required="required"
                   [(ngModel)]="model.cnpj" [mask]="'00.000.000/0000-00'"
                   [dropSpecialCharacters]="false"
            />
            <label>CNPJ</label>
          </div>
          <div class="group">
            <div class="row mt-5 mb-4">
              <div class="col-12 col-sm-6">
                <a href="javascript:void('');" (click)="isLoginPanel = true"
                   class="btn btn-block btn-outline-primary">voltar</a>
              </div>
              <div class="col-12 col-sm-6">
                <a href="javascript:void('');" (click)="onForgot(model.cnpj)"
                   class="btn btn-block btn-primary px-4">ENVIAR</a>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

    </ng-container>

  </div>
</div>
