 import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {Subscription} from 'rxjs';
import {EventBusService, Events} from '../../service/event-bus.service';
import {User} from '../../model/user/user';
import {Coupon} from '../../model/coupon/coupon';
import {CouponService} from '../../service/coupon.service';
import {LuckyNumber, LuckyResult} from '../../model/coupon/lucky';
import '../../../assets/libs/jquery.confetti';
import {NavigationEnd} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  modelResultNote: Coupon[] = [];
  modelResultNumbers: LuckyResult[] = [];
  model: User = new User();
  eventPageSingle: Subscription;
  eventOpenModal: Subscription;
  isSinglePage = false;
  isHeaderOpen = false;
  showMenu = false;
  isheaderSecondary = false;
  isPartnersHeader: boolean = false;

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService,
              public couponService: CouponService) {
    super(router);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateHeaderClass();
      }
    });
  }

  ngOnInit(): void {
    this.eventPageSingle = this.eventbus.on(Events.pageSingle, (data) => this.isSinglePage = data);
    this.eventOpenModal = this.eventbus.on(Events.openModal, (data) => this.onModal(data));
    this.toggleLoader(false);
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
      }, error: err => super.onError(err)
    });
  }

  updateHeaderClass() {
    // const isPartnersComponent =  this.router.url.includes('/ranking'); // this.router.url.includes('/parceiros') || this.router.url.includes('/ranking');
    // this.isPartnersHeader = isPartnersComponent;
  }

  ngAfterViewInit() {
    // window.onscroll = () => {
    //   const hero = document.querySelector<HTMLElement>('.ts-background');
    //   const header = document.querySelector<HTMLElement>('.header');
    //   if (!hero) {
    //     return;
    //   }
    //   window.pageYOffset >= hero.offsetHeight - 105 ? header.classList.add('bg-primary') : header.classList.remove('bg-primary');
    // };
  }

  ngOnDestroy() {
    this.eventPageSingle.unsubscribe();
    this.eventOpenModal.unsubscribe();
  }

  onNavScroll(str: string) {
    if (this.router.url !== '/') {
      this.router.navigate(['/']);
      this.handlerScroll(str, 500);
    } else {
      this.handlerScroll(str, 0);
    }
  }

  handlerScroll(str: string, ms: number) {
    if (this.isHeaderOpen && $(window).width() < 998) {
      this.toggleHeader();
    }
    setTimeout(() => {
      $('html, body').animate({scrollTop: $('#' + str).offset().top - 105}, 1400);
    }, ms);
  }

  toggleHeader() {
    this.isHeaderOpen = !this.isHeaderOpen;
  }

  onLogout() {
    this.confirmMessage('Atenção', 'Deseja realmente sair?', () => {
      localStorage.clear();
      this.userService.isAuthenticated = false;
      this.userService.updateUserData(new User());
      this.router.navigate(['/login']);
    });
  }

  onModal(d: any) {
    if (d === 'numbers') {
      this.getNumbers();
    } else if (d === 'nf') {
      this.getNF();
    }
  }

  getNF() {
    this.couponService.getCoupons().subscribe({
      next: data => {
        this.modelResultNote = data as Coupon[];
        $('#modalMyGrades').modal('show');
      }
    });
  }

  getNumbers() {
    this.couponService.getLuckyNumbers().subscribe({
      next: data => {
        this.modelResultNumbers = data as LuckyResult[];
        $('#modalLuckyNumber').modal('show');
      }
    });
  }
}
