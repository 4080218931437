<header class="header" [ngClass]="{'is-hidden' : isSinglePage}" [class.header-secondary]="isPartnersHeader"
        [class.header]="!isPartnersHeader">
  <div class="container container-fluid">
    <nav class="nav">
      <ul class="nav-list" [ngClass]="{'show-menu' : showMenu}">
        <li class="d-flex d-lg-none" (click)="showMenu=false" style="position: absolute;top: 20px;right: 33px">
          <svg-icon [svgStyle]="{'fill':'#fff'}" src="assets/svg/close.svg"></svg-icon>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" (click)="onNavScroll('home');showMenu=false"
             class="nav-list-item-link active">Home</a>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" (click)="onNavScroll('como-participar');showMenu=false"
             class="nav-list-item-link">A campanha</a>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" (click)="onNavScroll('premios');showMenu=false" class="nav-list-item-link">Premiação</a>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" (click)="goToInto('/parceiros', true);showMenu=false"
             class="nav-list-item-link">Parceiros</a>
        </li>
        <li class="nav-list-item" *ngIf="model.profile !== 'partner'">
          <a href="javascript:void('');" (click)="goToInto('/ranking', true);showMenu=false" class="nav-list-item-link">Ranking</a>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" (click)="onNavScroll('faq');showMenu=false" class="nav-list-item-link">FAQ</a>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" (click)="onNavScroll('fale-conosco');showMenu=false"
             class="nav-list-item-link">Fale
            Conosco</a>
        </li>
        <li class="nav-list-item">
          <a  target="_blank" download href="assets/files/Regulamento-AdrenalinaVW25.pdf"
             class="nav-list-item-link">Regulamento</a>
        </li>
        <li class="nav-list-item">
          <a href="javascript:void('');" class="nav-list-item-link" (click)="onLogout();showMenu=false"
             *ngIf="this.userService.isAuthenticated"><strong>Sair</strong></a>
          <a href="javascript:void('');" class="btn btn-custom-white" *ngIf="!this.userService.isAuthenticated"
             data-toggle="modal" data-target="#modalLogin">Entrar</a>
        </li>
      </ul>
    </nav>
  </div>
  <svg-icon (click)="showMenu=true" class="header-hamburguer" src="assets/svg/hamburguer.svg"></svg-icon>
  <div class="header-footer">
    <div class="header-footer-line full" [class.header-secondary-footer-line]="isPartnersHeader"
         [class.header-footer-line]="!isPartnersHeader"></div>
    <div class="container container-fluid">
      <div class="header-footer-line" [class.header-secondary-footer-line]="isPartnersHeader"
           [class.header-footer-line]="!isPartnersHeader"></div>
      <div class="header-footer-brand">
        <img *ngIf="!isPartnersHeader" src="assets/images/logo-vw.png" alt="Logo VW"/>
        <img *ngIf="isPartnersHeader" src="assets/images/headerlogodark.png" alt="Logo VW"/>
      </div>
      <div class="header-footer-line full" [class.header-secondary-footer-line]="isPartnersHeader"
           [class.header-footer-line]="!isPartnersHeader"></div>
    </div>
    <div class="header-footer-line full" [class.header-secondary-footer-line]="isPartnersHeader"
         [class.header-footer-line]="!isPartnersHeader"></div>
  </div>
</header>
<div class="header-options">
  <a href="javascript:void('');"
     (click)="this.isSinglePage = true; goToInto('/cadastro', true)" *ngIf="!this.userService.isAuthenticated"
     class="btn btn-lg btn-secondary">Quero participar</a>
  <ng-container *ngIf="this.userService.isAuthenticated">
    <div class="header-options-container">
      <div class="header-options-box">
        <div class="header-options-content">
          <div class="header-user-ico">
            <svg-icon class="svg d-flex align-items-center justify-content-center svg-md"
                      src="assets/svg/icoUser-2.svg"></svg-icon>
          </div>
          <div class="header-user-lbl text-white">Olá, {{ model.name }}</div>
        </div>
        <div class="header-options-content">
          <div class="header-user-options flex-row align-items-center">
            <div class="header-user-ico">
              <svg-icon class="svg d-flex align-items-center justify-content-center svg-md"
                        src="assets/svg/icoUser-2.svg"></svg-icon>
            </div>
            <a class="header-user-options-link" (click)="this.isSinglePage = true; goToInto('/editar', true)"
               href="javascript:void('');">Meus
              dados</a>
          </div>
        </div>
      </div>
        <div class="header-options-ranking bg-secondary">
          <div class="header-user-lbl">Ranking <strong class="text-primary">{{model.ranking}}º</strong></div>
        </div>
    </div>
  </ng-container>
</div>

<!-- Modal -->
<app-modal-my-grades [arrList]="modelResultNote" *ngIf="this.userService.isAuthenticated"></app-modal-my-grades>
<app-modal-lucky-number [arrList]="modelResultNumbers"
                        *ngIf="this.userService.isAuthenticated"></app-modal-lucky-number>

<!--
<canvas id="confettiCanvas" style="width:100%;position:fixed;top:0;left:0;display:block;z-index:9999999;"></canvas>
<div class="d-none">
  <button id="startConfetti">Start</button>
  <button id="stopConfetti">Stop</button>
  <button id="restartConfetti">Restart</button>
</div>
-->
