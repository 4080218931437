export class RankingCategoryDetail {
  category: string;
  objective: number;
  ranking: number;
  percentage: number;
  points: number;
  objectiveLabel: string;
  rankingLabel: string;
  isPercentage: boolean;
}
