<section class="page-single text-white page-single bg-white" [ngClass]="{'is-open': isPageSingle}">


  <div class="page-single-header header-inside-space">
    <div class="page-single-header-content mr-5">
      <a href="javascript:void('');" (click)="goBack()" class="page-single-header-btn-custom">
        <img src="assets/images/arrow-left-primary.png" alt="Voltar" />
      </a>
      <h1 class="page-single-header-title text-primary ml-4">Regulamento</h1>
    </div>

    <div class="page-single-vw">
      <div class="page-single-vw-line bg-primary full"></div>
      <div class="page-single-vw-brand">
        <svg-icon class="svg svg-logo svg-primary" [src]="'assets/svg/logoVW.svg'"></svg-icon>
      </div>
      <div class="page-single-vw-line bg-primary"></div>
    </div>
  </div>

  <div class="container">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="container d-flex flex-column align-items-center text-primary text-align-center">
          <h4 class="mb-4"><strong>Incentivo “Adrenalina”</strong></h4>
          <h3 class="title-regulation">
            <strong>VOLKSWAGEN DO BRASIL INDÚSTRIA DE VEICULOS AUTOMOTORES LTDA.
              MARGINAL DA VIA ANCHIETA, SN – KM 23,5 - DEMARCHI
              CEP  09823-901 - São Bernardo do Campo/SP
              CNPJ nº 59.104.422/0057-04</strong>
          </h3>
        </div>

        <div class="regulation text-primary">

          <h2>QUANDO? </h2>
          <p>Outubro de 2024 a Agosto de 2025. </p>
          <h2>QUEM PARTICIPA? </h2>
          <p>Todas as Concessionárias Volkswagen Automóveis com concessão plena, e PAT&rsquo;s que tenham sido nomeados até 30 de SETEMBRO de 2024. </p>
          <h2>QUAIS SÃO AS CATEGORIAS? </h2>
          <p>As concessionárias concorrerão em 4 categorias, no âmbito nacional, de acordo com o seu objetivo  de compras validado no PAC Pós-Vendas  2024/2025 (Potencial Anual do  Concessionário). </p>
          <h2>QUAIS SÃO OS CRITÉRIOS DE AVALIAÇÃO? </h2>
          <p>Pontuação Mensal – Itens regulares </p>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td valign="top"><p ><strong>ITENS DE AVALIAÇÃO </strong></p></td>
              <td valign="top"><p ><strong>PERÍODO DE AVALIAÇÃO </strong></p></td>
              <td valign="top"><p ><strong>PONTUAÇÃO MÁXIMA POSSÍVEL NO MÊS </strong></p></td>
            </tr>
            <tr>
              <td valign="top"><p >1                     Performance de compras de Peças </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>300 </p></td>
            </tr>
            <tr>
              <td valign="top"><p >2                 Performance de compras de Acessórios </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>150 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >3                Evolução    de Performance (P.P) em    Peças </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>300 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >4            Evolução    de Performance (P.P)    em Acessórios </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >5                                          Autopart </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >6                     CEM: Objetivo    Concessionária (Q6) </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>300 </p></td>
            </tr>
            <tr>
              <td valign="top"><p >7                                Agendamento Online </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>150 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >8                                  Revisão    Planejada </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >9                                Cadência    de Pedidos </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >10                           Reputação    Lojas On-Line </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>50 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >11                                   Linha Economy </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >12                                 Maxi Performance </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >13                                          Brazzo </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >14                                       Interozone </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >15                                           Wurth </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p >16                                          Tirreno </p></td>
              <td valign="top"><p>Mensal </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
          </table>
          <p>&nbsp;</p>
          <h2>AÇÕES EXTRAS </h2>
          <p>Adicionalmente aos pontos mensais regulares, ações pontuais poderão  ocorrer durante a campanha, com prévio aviso. </p>
          <h2>CRITÉRIOS DE PONTUAÇÃO </h2>
          <p>Os itens serão avaliados e pontuados mensalmente. Para o cálculo  da pontuação da etapa mensal,  bem como da etapa final, serão somados os pontos  conquistados nos meses referentes a cada uma das etapas. <br>
          </p>
          <p><strong>CÁLCULO DA PONTUAÇÃO MENSAL</strong></p>
          <p>&nbsp;</p>
          <h2>1. PERFORMANCE  DE COMPRAS DE PEÇAS </h2>
          <p>A pontuação será atribuída de acordo com a tabela abaixo, em função do percentual resultante do volume de pedidos  colocados de Peças somados, pelos objetivos também somados de compras do mês.</p>
          <p>&nbsp;</p>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td valign="top"><p><strong>PERCENTUAL </strong></p></td>
              <td valign="top"><p><strong>PONTOS </strong></p></td>
            </tr>
            <tr>
              <td valign="top"><p>De 0,00% a 99,99% </p></td>
              <td valign="top"><p>0 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>De 100,00% a 104,99% </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>De 105,00% a 109,99% </p></td>
              <td valign="top"><p>200 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>De 110,00% a 119,99% </p></td>
              <td valign="top"><p>250 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>A partir de 120,00% </p></td>
              <td valign="top"><p>300 </p></td>
            </tr>
          </table>
          <p>&nbsp;</p>
          <h2>2. PERFORMANCE  DE COMPRAS DE ACESSÓRIOS </h2>
          <p>A pontuação será atribuída de acordo com a tabela abaixo, em função do percentual resultante do volume de pedidos  colocados de Acessórios somados,  pelos objetivos também somados de compras do mês. </p>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td valign="top"><p><strong>PERCENTUAL </strong></p></td>
              <td valign="top"><p><strong>PONTOS </strong></p></td>
            </tr>
            <tr>
              <td valign="top"><p>De 0,00% a 99,99% </p></td>
              <td valign="top"><p>0 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>De 100,00% a 104,99% </p></td>
              <td valign="top"><p>50 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>De 105,00% a 109,99% </p></td>
              <td valign="top"><p>100 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>De 110,00% a 119,99% </p></td>
              <td valign="top"><p>125 </p></td>

            </tr>
            <tr>
              <td valign="top"><p>A partir de 120,00% </p></td>
              <td valign="top"><p>150 </p></td>
            </tr>
          </table>
          <p>&nbsp;</p>
          <h2>3. EVOLUÇÃO DE PERFORMANCE (P.P) EM PEÇAS </h2>
          <p>A pontuação será atribuída conforme  tabela abaixo em função da evolução, em (P.P), da performance  de Peças quando comparada com o mesmo período do ano anterior. </p>
          <p>  O DN que já estiver  acima de 140% em performance recebe automaticamente a pontuação máxima de 300 pontos. </p>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td valign="top">PERCENTUAL</td>
              <td valign="top">PONTOS (até 99,99%
                de performance)
              </td>
              <td valign="top">PONTOS (acima de 100% de performance)</td>
            </tr>
            <tr>
              <td valign="top"><p>De 0,0 p.p. a 1,99 p.p. </p></td>
              <td valign="top"><p>0 </p></td>
              <td valign="top"><p>0 </p></td>
            </tr>
            <tr>
              <td valign="top"><p>De 2,0 p.p. a 3,99 p.p. </p></td>
              <td valign="top"><p >25 </p></td>
              <td valign="top"><p>100 </p></td>
            </tr>
            <tr>
              <td valign="top"><p>De 4,00 p.p.    a 5,99 p.p. </p></td>
              <td valign="top"><p >50 </p></td>
              <td valign="top"><p>200 </p></td>
            </tr>
            <tr>
              <td valign="top"><p>De 6,00 p.p.    a 8,99 p.p. </p></td>
              <td valign="top"><p >75 </p></td>
              <td valign="top"><p>250 </p></td>
            </tr>
            <tr>
              <td valign="top"><p>A partir de 9,00 p.p. </p></td>
              <td valign="top"><p >100 </p></td>
              <td valign="top"><p>300 </p></td>
            </tr>
          </table>
          <h2>&nbsp;</h2>
          <h2>4. EVOLUÇÃO DE PERFORMANCE (P.P) EM ACESSÓRIOS </h2>
          <p>A pontuação será atribuída conforme  tabela seguinte em função da evolução, em (P.P), da performance  de Acessórios quando comparada com o mesmo período do ano anterior. </p>
          <p>O DN que já estiver acima  de 140% em performance recebe  automaticamente a pontuação máxima de 200 pontos.</p>
          <table border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td valign="top">PERCENTUAL</td>
              <td valign="top">PONTOS (até 99,99%
                de performance) </td>
              <td valign="top">PONTOS (acima de 100% de performance)</td>
            </tr>
            <tr>
              <td width="254" valign="top"><p>De 0,0 p.p. a 1,99 p.p. </p></td>
              <td width="216" valign="top"><p>0 </p></td>
              <td width="209" valign="top"><p>0 </p></td>
            </tr>
            <tr>
              <td width="254" valign="top"><p>De 2,0 p.p. a 3,99 p.p. </p></td>
              <td width="216" valign="top"><p>12,5 </p></td>
              <td width="209" valign="top"><p>50 </p></td>
            </tr>
            <tr>
              <td width="254" valign="top"><p>De 4,00 p.p.    a 5,99 p.p. </p></td>
              <td width="216" valign="top"><p>25 </p></td>
              <td width="209" valign="top"><p>75 </p></td>
            </tr>
            <tr>
              <td width="254" valign="top"><p>De 6,00 p.p.    a 8,99 p.p. </p></td>
              <td width="216" valign="top"><p>37,5 </p></td>
              <td width="209" valign="top"><p>100 </p></td>
            </tr>
            <tr>
              <td width="254" valign="top"><p>A partir de 9,00 p.p. </p></td>
              <td width="216" valign="top"><p>50 </p></td>
              <td width="209" valign="top"><p>200 </p></td>
            </tr>
          </table>
          <h2>&nbsp;</h2>
          <h2>5. AUTOPART </h2>
          <p>A pontuação será atribuída de acordo com a tabela  abaixo em função  do índice de pedidos colocados automaticamente pelo sistema,  levando em consideração o percentual colocado. </p>
          <table>
            <tr>
              <th>PERFORMANCE AUTOPART</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>DE 50% A 69,9%</td>
              <td>30</td>
            </tr>
            <tr>
              <td>DE 70% A 79,9%</td>
              <td>50</td>
            </tr>
            <tr>
              <td>ACIMA DE 80%</td>
              <td>100</td>
            </tr>
          </table>

          <p><strong>OBSERVAÇÃO: </strong><br>
            Base extraída do ASAS, com exclusão de pedidos de carro parado, DSH, itens promocionais e ferramentas. </p>
          <p>&nbsp;</p>
          <h2>6. CEM: Objetivo Concessionária (Q.6) </h2>
          <p>A pontuação será atribuída em função da avaliação do índice geral de satisfação da concessionária: CEM – Q6 (média móvel de 3 meses) . <br>
            Será considerada a Q6 – Satisfação Geral, conforme as regras de bonificações atreladas ao CEM, SEM RED FLAG. </p>
          <table>
            <tr>
              <th>NOTA (M.M TRIM)</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>Até 7,999</td>
              <td>0</td>
            </tr>
            <tr>
              <td>8,000 a 8,499</td>
              <td>50</td>
            </tr>
            <tr>
              <td>8,500 a 8,999</td>
              <td>100</td>
            </tr>
            <tr>
              <td>9,000 a 9,499</td>
              <td>250</td>
            </tr>
            <tr>
              <td>Acima 9,500</td>
              <td>300</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <h2>7. AGENDAMENTO ONLINE </h2>
          <p>A pontuação será atribuída em função da avaliação da representatividade (penetration) de passagens agendadas pelos  canais online em relação as passagens no concessionário. </p>
          <p>  A avaliação será feita pelo cruzamento do chassi único  apurado no Autoservice com a quantidade de agendamento online recebido mensalmente (não é cumulativo) </p>
          <p>  O concessionário, para ter direito  à pontuação do Agendamento Online,  deverá ter frequência de envio das notas  fiscais de oficina igual ou superior a 80% mensalmente. </p>
          <table>
            <tr>
              <th>VARIAÇÃO</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>De 15% a 19,99% das passagens</td>
              <td>50</td>
            </tr>
            <tr>
              <td>De 20% a 29,99% das passagens</td>
              <td>100</td>
            </tr>
            <tr>
              <td>Acima de 30% das passagens</td>
              <td>150</td>
            </tr>
          </table>

          <p class="d-flex justify-content-center align-items-center"><img src="assets/images/terms/7-a.png" alt="7-a"/></p>
          <p>&nbsp;</p>
          <h2>8. REVISÃO PLANEJADA </h2>
          <p>A pontuação será mensal e atribuída em função do penetration de vendas de pacotes de revisão atingido pela concessionária dentro do  mês. <br>
            O cálculo do penetration é a divisão da quantidade de pacotes de Revisões Planejadas vendidas no concessionário em Vendas e no  Pós Vendas, ou seja,  independentemente do canal, dividido pela quantidade de veículos vendidos pelo concessionário, sem revisão de série, (Privado  Varejo + Produtor  Rural) dentro do mesmo mês. </p>
          <table>
            <tr>
              <th>VARIAÇÃO</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>De 0 a 34,99%</td>
              <td>0</td>
            </tr>
            <tr>
              <td>De 35% a 49,99%</td>
              <td>50</td>
            </tr>
            <tr>
              <td>Acima de 50%</td>
              <td>150</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <h2>9. CADÊNCIA DE PEDIDOS </h2>
          <p>O grupo deve realizar 50% do objetivo  de peças até o dia 15 (metade  do mês) e 100% até 2 dias úteis antes do fechamento do mês (feriados  regionais serão desconsiderados). </p>
          <p>  Caso o grupo não cumpra 100% de performance de peças no mês a pontuação será invalidada. </p>
          <table>
            <tr>
              <th>VARIAÇÃO</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>50% até dia 15 (metade do mês)</td>
              <td>50</td>
            </tr>
            <tr>
              <td>100% até 2 dias úteis</td>
              <td>50</td>
            </tr>
          </table>

          <p>OBSERVAÇÃO: critério  válido de <strong>novembro de 2024 a agosto de 2025. </strong></p>
          <p><strong>Considerações Importantes: </strong></p>
          <ol>
            <li>Para o critério de dias úteis serão considerados apenas os feriados  NACIONAIS. </li>
            <li>Caso o grupo não cumpra 100% de performance de peças no mês a pontuação será invalidada. </li>
            <li>Para ter direito aos 100 pontos  mensais é obrigatório que o grupo  cumpra com as 2 faixas  de cadência estipuladas para  o mês. </li>
          </ol>
          <p>&nbsp;</p>
          <h2>10. REPUTAÇÃO LOJAS ON-LINE </h2>
          <p>A pontuação será atribuída de acordo com a tabela  abaixo em função  do índice de reputação de cada  canal de venda online:</p>
          <table>
            <tr>
              <th>Mercado Livre</th>
              <th>Reputação verde</th>
              <th>50 pontos</th>
            </tr>
            <tr>
              <td>Peças VW</td>
              <td>5 estrelas</td>
              <td>50 pontos</td>
            </tr>
          </table>

          <p><strong>Observação: </strong>a base de dados será enviada pelo canal de venda online, sem extração pela VW. </p>
          <p>&nbsp;</p>
          <p><strong>PONTUAÇÃO</strong><strong> PARCEIROS</strong><strong> </strong></p>
          <p>OBSERVAÇÕES GERAIS: (Válidas para todos os parceiros) </p>
          <ol>
            <li>Os produtos são aplicados na campanha pelo preço com IPI, PIS, COFINS, ICMS (regionalizado). </li>
            <li>Quando o pedido é colocado pela concessionária, o sistema já calcula o ICMS do estado. </li>
          </ol>
          <p>&nbsp;</p>
          <h2>11. LINHA ECONOMY </h2>
          <p><img src="assets/images/terms/linhaeconomy.png" /></p>

          <p>A pontuação mensal terá como base o percentual  comparativo entre o valor dos pedidos colocados no mês dos produtos da Linha Economy  disponíveis e o objetivo mensal  de compras da concessionária desses mesmos produtos. </p>
          <p>  Os objetivos da Linha Economy  são individuais e calculados utilizando o objetivo de compras de peças  do PAC Pós-Vendas 2023 de cada concessionária e estarão à sua disposição no site da campanha. </p>
          <p>A pontuação de cumprimento do objetivo será de acordo  com a escala abaixo:</p>

          <table>
            <tr>
              <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
              <th>PONTUAÇÃO</th>
            </tr>
            <tr>
              <td>0 - 89,99%</td>
              <td>0</td>
            </tr>
            <tr>
              <td>90% - 99,99%</td>
              <td>50</td>
            </tr>
            <tr>
              <td>100 - 109,99</td>
              <td>100</td>
            </tr>
            <tr>
              <td>119,99%</td>
              <td>150</td>
            </tr>
            <tr>
              <td>Acima de 120%</td>
              <td>200</td>
            </tr>
          </table>


          <p><strong>NOTA: serão consideradas na campanha todas as peças disponíveis da Linha Economy. </strong></p>
          <p>&nbsp;</p>
          <h1>12. MAXI PERFORMANCE </h1>
          <p>ÓLEO ORIGINAL VOLKSWAGEN MAXI PERFORMANCE </p>
          A pontuação  mensal terá como base o percentual  comparativo entre o volume em litros  dos pedidos colocados no mês e o volume  mensal objetivado.
          <p>Para se ter elegibilidade da pontuação, o grupo econômico  deve bater o alvo de óleo de motor. </p>
          <p>A pontuação de cumprimento do objetivo será de  acordo com a escala abaixo:</p>
          <table>
            <tr>
              <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
              <th>PONTUAÇÃO</th>
            </tr>
            <tr>
              <td>De 100% a 109,99%</td>
              <td>100</td>
            </tr>
            <tr>
              <td>De 110% a 119,99%</td>
              <td>150</td>
            </tr>
            <tr>
              <td>Acima de 120%</td>
              <td>200</td>
            </tr>
          </table>

          <p><strong>NOTA </strong><br>
            <strong>São considerados na campanha todos os óleos comercializados pela Volkswagen (litro ou tambor Maxi Performance). </strong></p>
          <table>
            <tr>
              <th>APLICAÇÃO</th>
              <th>SKU VW</th>
              <th>DESCRIÇÃO</th>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-S55-167-R7-BRA</td>
              <td>Max performance OW-30 502 505 CX 12X1L</td>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-S55-545-M2-BRA</td>
              <td>Max performance OW-30 507 CX 12X1L</td>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-S55-545-M9-BRA</td>
              <td>Max performance OW-30 507 TB 209L</td>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-S55-553-R2-BRA</td>
              <td>Max performance 5W-40 508.88 509.99 CX 12X1L</td>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-S55-553-R6-BRA</td>
              <td>Max performance 5W-40 508.88 509.99 TB 209L</td>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-198-553</td>
              <td>Max performance 5W-40 508.88 509.99 G TB 209L</td>
            </tr>
            <tr>
              <td>Oficina</td>
              <td>G-S60-577-M2-BRA</td>
              <td>Max performance OW-20 508 509 12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-020-C2-BRA</td>
              <td>Max performance OW-20 ECONOMY CX 12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-020-R9-BRA</td>
              <td>Max performance OW-20 ECONOMY TB 209L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-250-R2-BRA</td>
              <td>Max performance 20W-50 SL CX12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-107-M2-BRA</td>
              <td>Max performance 10W-40 501 505 CX 12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-530-R2-BRA</td>
              <td>Max performance 5W-30 SN GF-5 CX 12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-530-R9-BRA</td>
              <td>Max performance 5W-30 SN GF-5 TB 209L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-502-M2-BRA</td>
              <td>Max performance 5W-40 502 505 CX 12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-502-M9-BRA</td>
              <td>Max performance 5W-40 502 505 TB 209L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-530-Q2-BRA</td>
              <td>Max performance 5W-30 SP GF-6 CX 12X1L</td>
            </tr>
            <tr>
              <td>Economy</td>
              <td>G-JZZ-107-R2-BRA</td>
              <td>Max performance 10W-40 SP 501 505 12X1L</td>
            </tr>
          </table>

          <p><strong>PRODUTOS MAXI PERFORMANCE AVALIADOS NA CAMPANHA </strong></p>
          <p>&nbsp;</p>
          <h1>13. BRAZZO </h1>
          <p><img src="assets/images/brazzo-lg.png" /></p>
          <table>
            <tr>
              <th>OBJETIVO QUÍMICOS</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th >CATEGORIA</th>
              <th>OBJETIVO MENSAL</th>
              <th>100%</th>
              <th>110%</th>
              <th>120%</th>
            </tr>
            <tr>
              <td>A</td>
              <td>R$ 10.000,00</td>
              <td rowspan="4">100 Pontos</td>
              <td rowspan="4">150 Pontos</td>
              <td rowspan="4">200 Pontos</td>
            </tr>
            <tr>
              <td>B</td>
              <td>R$ 8.000,00</td>
            </tr>
            <tr>
              <td>C</td>
              <td>R$ 6.000,00</td>
            </tr>
            <tr>
              <td>D</td>
              <td>R$ 4.000,00</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <table>
            <tr>
              <th>OBJETIVO ACESSÓRIOS (PET, FRISOS E CADEIRINHA)</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>CATEGORIA</th>
              <th>OBJETIVO MENSAL</th>
              <th>100%</th>
              <th>110%</th>
              <th>120%</th>
            </tr>
            <tr>
              <td>A</td>
              <td>R$ 12.000,00</td>
              <td rowspan="4">100 Pontos</td>
              <td rowspan="4">150 Pontos</td>
              <td rowspan="4">200 Pontos</td>
            </tr>
            <tr>
              <td>B</td>
              <td>R$ 10.000,00</td>
            </tr>
            <tr>
              <td>C</td>
              <td>R$ 7.500,00</td>
            </tr>
            <tr>
              <td>D</td>
              <td>R$ 5.000,00</td>
            </tr>
          </table>


          <p><strong> </strong></p>
          <p>&nbsp;</p>
          <h1>14. INTEROZONE BRASIL</h1>
          <p><img src="assets/images/partners-interozone.png" /></p>
          <table>
            <tr>
              <th >PRODUTOS INTEROZONE ELEGÍVEIS NA CAMPANHA</th>
              <th></th>
            </tr>
            <tr>
              <th>CÓDIGO</th>
              <th>DESCRIÇÃO</th>
            </tr>
            <tr>
              <td>6EA 096 301 A</td>
              <td>ODORIZADOR ABERSENSE 100ml / 63g</td>
            </tr>
            <tr>
              <td>6EA 096 301 B</td>
              <td>LIMPEZA DA CAIXA EVAPORADORA 500ml</td>
            </tr>
            <tr>
              <td>6EA 096 311</td>
              <td>CRISTALIZADOR DE VIDROS 30ML</td>
            </tr>
            <tr>
              <td>6EA 096 301 D</td>
              <td>LIMPADOR DE DPF 300ml / 290g</td>
            </tr>
            <tr>
              <td>6EA 096 309</td>
              <td>PROTETOR DE MOTORES 65ML / 40G</td>
            </tr>
            <tr>
              <td>6EA 096 150 A</td>
              <td>ENCHE E VEDA PNEU 400ml / 260g</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <table>
            <tr>
              <th>CATEGORIA</th>
              <th>OBJETIVO DE COMPRA</th>
            </tr>
            <tr>
              <td>A</td>
              <td>R$ 15.000,00</td>
            </tr>
            <tr>
              <td>B</td>
              <td>R$ 12.000,00</td>
            </tr>
            <tr>
              <td>C</td>
              <td>R$ 8.000,00</td>
            </tr>
            <tr>
              <td>D</td>
              <td>R$ 4.000,00</td>
            </tr>
          </table>


          <p>&nbsp;</p>
          <h2>QUÍMICOS (ACESSÓRIOS) </h2>
          <table>
            <tr>
              <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
              <th>PONTUAÇÃO</th>
            </tr>
            <tr>
              <td>100% &gt; acima</td>
              <td>200</td>
            </tr>
            <tr>
              <td>90% a 99,99%</td>
              <td>160</td>
            </tr>
            <tr>
              <td>85% a 89,99%</td>
              <td>80</td>
            </tr>
            <tr>
              <td>80% a 84,99%</td>
              <td>40</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <h2><strong>OXI-SANITIZAÇÃO</strong><strong> </strong><strong>(ACESSÓRIOS)</strong><strong> </strong></h2>
          <table>
            <tr>
              <th>VOLUME DE CARTÕES</th>
              <th>PONTUAÇÃO MENSAL</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
            </tr>
            <tr>
              <td>100</td>
              <td>80</td>
              <td>60</td>
              <td>40</td>
              <td>50</td>
            </tr>
            <tr>
              <td>120</td>
              <td>100</td>
              <td>80</td>
              <td>60</td>
              <td>75</td>
            </tr>
            <tr>
              <td>140</td>
              <td>120</td>
              <td>100</td>
              <td>80</td>
              <td>100</td>
            </tr>
            <tr>
              <td>160 &gt;</td>
              <td>140 &gt;</td>
              <td>120 &gt;</td>
              <td>100 &gt;</td>
              <td>200</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <h1>15. WURTH</h1>
          <p><img src="assets/images/wurthbig.png" /></p>
          <p>As concessionárias serão pontuadas com base nas compras dos produtos WURTH cadastrados na campanha. </p>
          <h3>*A lista de produtos será revisada mensalmente. </h3>
          <p>Os objetivos  individuais das concessionárias estarão disponíveis no site da campanha. <br>
            A pontuação mensal  será determinada baseada  no atingimento do objetivo de acordo com a  tabela abaixo: </p>
          <p><strong>1. ATIVAÇÃO DO CNPJ – 20 PONTOS </strong></p>
          <table>
            <tr>
              <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>0% a 79,99%</td>
              <td>0</td>
            </tr>
            <tr>
              <td>80% a 89,99%</td>
              <td>50</td>
            </tr>
            <tr>
              <td>90% a 99,99%</td>
              <td>80</td>
            </tr>
            <tr>
              <td>&gt;= 100%</td>
              <td>120</td>
            </tr>
          </table>
          <p>&nbsp;</p>
          <p><strong>3. PONTUAÇÃO WURTH POWER</strong></p>
          <p>  Comprando os itens HSW PRO3 80 ML e CAIXA EVAPORADORA 250 ml ganha pontuação de 30 pontos.</p>
          <p><strong>  4. PONTUAÇÃO PACOTE FIDELIDADE</strong></p>
          <p>  Comprando o mix de produtos abaixo, mínimo de 1 caixa por produto, ganhe 30 pontos.</p>
          <p><strong>  5. Clientes novos ou sem histórico de compras Wurth, os objetivos são estabelecidos de acordo com a sua<br>
            categorização VW, conforme a seguinte tabela:</strong></p>
          <table>
            <tr>
              <th>CATEGORIA</th>
              <th>OBJETIVO MENSAL (R$) X PONTUAÇÃO</th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th>80 PONTOS</th>
              <th>100 PONTOS</th>
              <th>120 PONTOS</th>
            </tr>
            <tr>
              <td>A</td>
              <td>R$ 5.000,00</td>
              <td>R$ 10.000,00</td>
              <td>R$ 15.000,00</td>
            </tr>
            <tr>
              <td>B</td>
              <td>R$ 4.000,00</td>
              <td>R$ 7.500,00</td>
              <td>R$ 11.000,00</td>
            </tr>
            <tr>
              <td>C</td>
              <td>R$ 3.000,00</td>
              <td>R$ 5.500,00</td>
              <td>R$ 7.500,00</td>
            </tr>
            <tr>
              <td>D</td>
              <td>R$ 2.000,00</td>
              <td>R$ 3.000,00</td>
              <td>R$ 4.000,00</td>
            </tr>
          </table>

          <p><strong>PRODUTOS WURTH QUE FAZEM PARTE DA CAMPANHA: </strong><br>
            <strong>Enviaremos mensalmente a relação dos itens que fazem parte da Campanha. </strong></p>
          <p>&nbsp;</p>
          <h1>16. TIRRENO</h1>
          <p><img src="assets/images/tirreno.png" /></p>
          <p>As concessionárias serão pontuadas na compra dos produtos Tirreno  participantes da campanha,  conforme descrito abaixo:</p>
          <table>
            <tr>
              <th>CATEGORIA</th>
              <th>OBJETIVO DE COMPRA</th>
            </tr>
            <tr>
              <td>A</td>
              <td>R$ 20.000,00</td>
            </tr>
            <tr>
              <td>B</td>
              <td>R$ 14.000,00</td>
            </tr>
            <tr>
              <td>C</td>
              <td>R$ 10.000,00</td>
            </tr>
            <tr>
              <td>D</td>
              <td>R$ 6.000,00</td>
            </tr>
          </table>
          <p>&nbsp;</p>

          <table>
            <tr>
              <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>0% a 79,99%</td>
              <td>0</td>
            </tr>
            <tr>
              <td>80% a 84,99%</td>
              <td>40</td>
            </tr>
            <tr>
              <td>85% a 89,99%</td>
              <td>60</td>
            </tr>
            <tr>
              <td>90% a 94,99%</td>
              <td>140</td>
            </tr>
            <tr>
              <td>95% a 99,99%</td>
              <td>160</td>
            </tr>
            <tr>
              <td>100%</td>
              <td>180</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>BÔNUS*</td>
              <td>20</td>
            </tr>
          </table>


          <p>&nbsp;</p>

          <p><strong>(*) Bônus: Para o cliente ganhar a pontuação bônus deverá conter obrigatoriamente em seu pedido o produto: </strong></p>
          <ol>
            <li><strong>Fluido para freio DOT 4LV 500ml (B 000 750 Q2) e Aditivo de Radiador (G 12E 040 R2), independente do % da meta que ele comprar. </strong></li>
            <li><strong>No decorrer da Campanha poderemos fazer mudanças no mix de produtos e pontuações adicionais. </strong></li>
          </ol>
          <p>PRODUTOS TIRRENO AVALIADOS NA CAMPANHA </p>
          <table>
            <tr>
              <th>CÓDIGO</th>
              <th>DESCRIÇÃO</th>
            </tr>
            <tr>
              <td>G 12E 040 R2</td>
              <td>Aditivo de Radiador pronto uso G12evo 1L</td>
            </tr>
            <tr>
              <td>G JZZ 040 R3</td>
              <td>Aditivo de Radiador pronto uso G12evo 5vL</td>
            </tr>
            <tr>
              <td>B 000 750 Q2</td>
              <td>Fluido para Freio DOT 4 LV 500 ml</td>
            </tr>
            <tr>
              <td>G 052 164 R1</td>
              <td>Limpa para-brisas 200 ml</td>
            </tr>
            <tr>
              <td>B JZZ 700 Q2</td>
              <td>Fluido para Freio DOT 4 500 ml</td>
            </tr>
          </table>

          <p>&nbsp;</p>
          <p><strong>PONTUAÇÕES PERIÓDICAS</strong></p>
          <p>OBSERVAÇÕES GERAIS: </p>
          <ol>
            <li>- Ao final da campanha, cada concessionária que cumprir um dos critérios abaixo ganhará uma pontuação  extra correspondente. </li>
            <li>- Cada um dos critérios apresentam pontuações diferentes. </li>
            <li>- Pontuações válidas apenas para a etapa final. </li>
          </ol>
          <h3>1 - PROGRAMA  AUTONOMIA </h3>
          <p>Durante o período da campanha, haverá 2 medições  consolidadas do Programa  Autonomia: </p>
          <ol>
            <ol>
              <li><strong><em>meses de setembro, outubro e novembro (resultado válido para o 1º Tri/24); </em></strong></li>
              <li><strong><em>meses de dezembro, janeiro e fevereiro (resultado válido para o 2º Tri/24). </em></strong></li>
            </ol>
          </ol>
          <p>Essas medições indicarão a evolução do concessionário durante  as duas classificações do Programa  Autonomia realizadas dentro  do período da campanha. Cada concessionário receberá  uma pontuação específica, de  acordo com tais classificações: </p>
          <p>Exemplificando, o concessionário XPTO recebeu as seguintes classificações: </p>
          <ol>
            <ol>
              <li>Período de setembro, outubro e novembro  (resultado válido para o 1º Tri/24): A+</li>
              <li>Período de dezembro,  janeiro e fevereiro  (resultado válido para o 2º Tri/24): B</li>
            </ol>
          </ol>
          <p>Nesse contexto, o concessionário XPTO receberá 500 pontos referente à classificação A+ no primeiro  período e mais 125 pontos referente à classificação B do segundo  período, totalizando 625 pontos para a campanha Adrenalina. A pontuação  referente ao Programa Autonomia será atribuída apenas
            no final da campanha. </p>
          <p>&nbsp;</p>
          <h3>2 - ÍNDICE DE CAPACITAÇÃO DE PÓS-VENDAS (ANUAL) </h3>
          <p>No fechamento  da campanha (Nov. 2025) será feito o  levantamenti do índice de capacitação das áreas de Pós-Vendas Comercial e Pós-Vendas Técnico,  bem como a certificação dos seguintes profissionais do Pós-Vendas Concessionárias: </p>
          <ol>
            <li>Quantidade de Mecânico de Revisão x Quantidade de Mecânico Certificados (PCMR); </li>
            <li>Quantidade  de Consultores Técnicos x Consultores Técnicos Certificados (PCCT); </li>
            <li>Pelo menos 01 Eletricista certificado como Técnico  de Diagnóstico; </li>
            <li>Pelo menos 01 Chefe de Oficina  ou Lider de Mecânica certificado como Ténico Mestre; </li>
            <li>Pelo menos 01  gestor ou supervisor certificado (PCGPV). </li>
          </ol>
          <p>As Concessionárias poderão  receber até 300 potos extras,  conforme suas médias do Índice de Capacitação, conforme tabela abaixo: </p>
          <p class="d-flex justify-content-center align-items-center"><img src="assets/images/terms/pontuacao-periodica.png" /></p>

          <table>
            <tr>
              <th>ÍNDICE</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>&ge;80,00%</td>
              <td>300</td>
            </tr>
            <tr>
              <td>70,00% a 79,99%</td>
              <td>150</td>
            </tr>
            <tr>
              <td>60,00% a 69,99%</td>
              <td>100</td>
            </tr>
            <tr>
              <td>50,00% a 59,99%</td>
              <td>50</td>
            </tr>
            <tr>
              <td>&le;50,00%</td>
              <td>0</td>
            </tr>
          </table>


          <p>As Concessionárias poderão  receber até 300 pontos extras,  conforme a quantidade de profissionais  certificados em sua área de atuação, conforme tabela abaixo: </p>
          <table>
            <tr>
              <th>ÍNDICE CERTIFICAÇÃO</th>
              <th>PONTOS</th>
            </tr>
            <tr>
              <td>50% Mecânicos de Revisão + 50% Consultor Técnico + 01 Técnico de Diagnóstico + 01 Técnico Mestre + 01 Gestor de Peças ou Serviços</td>
              <td>300</td>
            </tr>
            <tr>
              <td>50% Mecânicos de Revisão + 50% Consultor Técnico + 01 Técnico de Diagnóstico + 01 Técnico Mestre</td>
              <td>150</td>
            </tr>
            <tr>
              <td>50% Mecânicos de Revisão + 50% Consultor Técnico + 01 Técnico de Diagnóstico</td>
              <td>100</td>
            </tr>
            <tr>
              <td>50% Mecânicos de Revisão + 50% Consultor Técnico</td>
              <td>50</td>
            </tr>
            <tr>
              <td>50% Mecânicos de Revisão ou 50% Consultor Técnico</td>
              <td>25</td>
            </tr>
            <tr>
              <td>&lt; 50% Mecânicos de Revisão ou Consultor Técnico</td>
              <td>0</td>
            </tr>
          </table>


          <p>&nbsp;</p>
          <h3>CSI ou Pesquisa Clube (ACUMULADO DAS ONDAS 1-6/24) (ANUAL) </h3>
          <p>CSI (Customer Satisfaction Index) - Pontua  a concessionária que obtiver a nota acumulada das ondas 1 a 6, maior ou igual a 86,0% na nota top 2  box (9 e 10). </p>
          <h3>PONTUAÇÃO:  500 pontos </h3>
          <p>OBS. 1: Válida apenas  para concessionários da categoria  A. </p>
          <p>&nbsp;</p>
          <p><strong>REGRA DE</strong><strong> ELEGIBILIDADE</strong><strong> </strong></p>
          <p>Para ser elegível  à premiação da campanha (Etapa  Intermediária ou Etapa  Final), além de estar classificada pelo total de pontos na sua  categoria, a concessionária terá de cumprir  também as seguintes condições: </p>
          <ol>
            <li>Alcançar, no mínimo,  100% do seu objetivo acumulado de compras de Peças e Acessórios nos meses  do período avaliado. </li>
            <li>Se a concessionária ganhadora pertencer a determinado Grupo  Econômico Regionalizado (conforme regras do protocolo  da política de comercialização de Peças e Acessórios), para fazer jus às premiações,  o grupo todo deverá alcançar, no mínimo, 100% do objetivo acumulado de  compras de Peças e Acessórios nos meses do período avaliado. </li>
            <li>A concessionária deve possuir a certificação do ATC no mês do fechamento das etapas. </li>
            <li>Ao final do período da campanha, a concessionária  deve ter pontuado no mínimo 2.000 pontos no acumulado do período com os fornecedores parceiros, independentemente de quais parceiros a concessionária escolheu  adquirir seus produtos. </li>
            <li>O DN precisa  estar devidamente com PAC Pós-Vendas 2024/2025 validado e assinado. </li>
          </ol>
          <p><strong>NOTA: </strong><br>
            Caso alguma das concessionárias classificadas em uma categoria  (pelo total de pontos) deixe de cumprir alguma das condições de  elegibilidade, o prêmio passará automaticamente à concessionária colocada  imediatamente abaixo na escala de pontuação. Caso nenhuma das concessionárias,  de determinada categoria, satisfaça a todas as condições de elegibilidade, as vagas disponíveis para essa categoria ficarão <br>
            a  cargo do comitê da campanha,  que será composto  por todos os gestores diretos  do Diretor de Pós-Vendas. </p>
          <p>&nbsp;</p>
          <h2>CRITÉRIOS DE DESEMPATE </h2>
          <p>Entre duas ou mais concessionárias empatadas na pontuação  da Etapa Intermediária ou Final, será vencedora aquela que possuir maior  performance de peças no grupo; </p>
          <p>Persistindo o empate  pelo primeiro critério, será vencedora a concessionária que possuir maior performance de acessórios no grupo; </p>
          <p>Se depois de aplicado esses dois critérios  ainda persistir o empate, será vencedora a concessionária  que obtiver o melhor índice do CEM. </p>
          <h2>CRITÉRIOS DE PREMIAÇÃO </h2>
          <p>1 – Premiação Intermediária (Gerentes e Equipes) </p>
          <p>  A premiação  da Etapa Intermediária para as Equipes: </p>
          <p>Viagem para Chapada dos Guimarães</p>
          <p>  A Etapa Intermediária terá avaliação dentro das quatro categorias (A, B, C e D), pontuando mensalmente as seguintes concessionárias: </p>
          <p><strong>Categoria A </strong>- sete primeiras concessionárias <strong><br>
            Categoria B </strong>- seis primeiras concessionárias <strong><br>
            Categoria C </strong>- cinco primeiras concessionárias <strong><br>
            Categoria D </strong>- quatro primeiras  concessionárias</p>
          <table>

            <tr>
              <th>CATEGORIAS</th>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>TOTAL</th>
            </tr>
            <tr>
              <td>QUANTIDADE DE CONCESSIONÁRIAS PREMIADAS</td>
              <td>7</td>
              <td>6</td>
              <td>5</td>
              <td>4</td>
              <td>22</td>
            </tr>
            <tr>
              <td>QUANTIDADE DE GERENTES PREMIADOS POR CONCESSIONÁRIA</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>8</td>
            </tr>
            <tr>
              <td>QUANTIDADE DE FUNCIONÁRIOS PREMIADOS POR CONCESSIONÁRIA</td>
              <td>3</td>
              <td>3</td>
              <td>2</td>
              <td>2</td>
              <td>10</td>
            </tr>
            <tr>
              <td>TOTAL DE PREMIADOS POR CONCESSIONÁRIA</td>
              <td>5</td>
              <td>5</td>
              <td>4</td>
              <td>4</td>
              <td>18</td>
            </tr>
            <tr>
              <td>TOTAL DE PREMIADOS POR CATEGORIA</td>
              <td>35</td>
              <td>30</td>
              <td>20</td>
              <td>16</td>
              <td>101</td>
            </tr>
          </table>


          <p><strong>OBS: O fechamento da Etapa Intermediária ocorrerá com base na pontuação acumulada até o dia 30/04/2025.</strong></p>
          <p>Premiação Etapa Final (gerentes e acompanhantes) Viagem para a Croácia <br>
            A etapa final terá avaliação nacional dentro das quatro categorias (A, B, C e D), premiando da seguinte forma: </p>
          <p><strong>Categoria A </strong>- três  primeiras concessionárias <strong><br>
            Categoria B </strong>- duas primeiras concessionárias <strong><br>
            Categoria C </strong>- duas primeiras concessionárias <strong><br>
            Categoria D </strong>- duas primeiras concessionárias </p>
          <p>  Caso alguma concessionária de uma ou mais categorias não se classifique, as vagas disponíveis ficarão a cargo do comitê da campanha. </p>
          <p>  Premiação destinada aos gerentes e acompanhantes de Pós-Vendas das concessionárias melhores classificadas e com a quantidade prevista para cada  categoria, conforme tabela a seguir: </p>
          <table>
            <tr>
              <th>CATEGORIAS</th>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>
              <th>TOTAL</th>
            </tr>
            <tr>
              <td>QUANTIDADE DE CONCESSIONÁRIAS PREMIADAS</td>
              <td>3</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>09</td>
            </tr>
            <tr>
              <td>QUANTIDADE DE GERENTES PREMIADOS POR CONCESSIONÁRIA</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>08</td>
            </tr>
            <tr>
              <td>QUANTIDADE DE ACOMPANHANTES</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>08</td>
            </tr>
            <tr>
              <td>TOTAL DE PREMIADOS POR CONCESSIONÁRIA</td>
              <td>4</td>
              <td>4</td>
              <td>4</td>
              <td>4</td>
              <td>16</td>
            </tr>
            <tr>
              <td>TOTAL DE PREMIADOS POR CATEGORIA</td>
              <td>12</td>
              <td>08</td>
              <td>08</td>
              <td>08</td>
              <td>36</td>
            </tr>
          </table>

          <p><strong>NOTAS: </strong></p>
          <ol>
            <li>Caso a concessionária premiada  possua apenas um gerente de Pós-Vendas responsável pelas duas áreas (Peças e Serviços), ele poderá indicar alguém da área de Pós-Vendas da concessionária para a vaga aberta; </li>
            <li>Cada gerente  poderá optar por levar apenas  um funcionário de Pós-Vendas para a viagem (etapa intermediária); </li>
            <li>Cada gerente  poderá optar por levar um funcionário de Pós-Vendas ou acompanhante para a viagem (etapa final); </li>
            <li>A Volkswagen terá o prazo de até um ano para a entrega da viagem ou um prazo maior,  caso não seja possível a realização da viagem por motivo de força maior; </li>
          </ol>
          A Volkswagen se mantém no direito de alterar o destino da viagem de premiação  da campanha por qualquer motivo de força maior.
          <br>
          <br>
          <p><strong>ESCLARECIMENTOS FINAIS </strong></p>
          <p>  Quanto  aos resultados, é preciso  que se esclareça que a Volkswagen poderá reavaliar, a qualquer momento,  as premiações atribuídas, mediante análise posterior, declarando os legítimos vencedores, caso seja constatada alguma  irregularidade em dados ou nos resultados fornecidos pelas próprias concessionárias. </p>
          <p>  No fechamento de cada etapa da campanha,  todos os pedidos  cancelados, seja pela concessionária  ou automaticamente pelo sistema, passarão por auditoria para validação de sua  autenticidade. </p>
          <p>  A Volkswagen se reserva também  o direito de desclassificar da campanha, a qualquer momento, concessionárias que venham a adotar procedimentos incompatíveis com os padrões de conduta,  ainda que tais procedimentos não estejam previstos de forma explícita no  regulamento. </p>
          <p><strong>Todas as concessionárias estarão sujeitas a auditoria por parte da Volkswagen quanto aos dados fornecidos  para a campanha.</strong><strong> </strong></p>
          <p>&nbsp;</p>


        </div>
      </div>

    </div>


  </div>

  <app-footer></app-footer>


</section>

