import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {User} from '../../model/user/user';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import * as moment from 'moment';
import {EmitEvent, EventBusService, Events} from '../../service/event-bus.service';
import {Uf} from '../../model/user/state';
import {City} from '../../model/user/city';
import {ResponseServer} from '../../model/responseServer';
import {Token} from '../../model/user/token';
import {changePassword} from '../../model/user/changePassword';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent implements OnInit, OnDestroy {
  model: User = new User();
  modelChangePassword: changePassword = new changePassword();
  modelBirthday = null;
  myRecaptcha = false;
  isPageSingle = false;

  promoEmailConfirm = '';
  promoPasswordConfirm = '';

  selectedState = null;
  selectedCity = null;
  listState: Uf[] = [];
  listCity: City[] = [];

  constructor(public router: Router,
              private eventbus: EventBusService,
              public userService: UserService) {
    super(router);
  }

  ngOnInit(): void {
    this.eventbus.emit(new EmitEvent(Events.pageSingle, true));
    setTimeout(() => {
      this.toggleLoader(false);
      this.isPageSingle = true;
    }, 500);
    this.onState();
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;

        if (this.isNullOrUndefined(this.model.id)) {
          // window.location.href = '/login';
        }
        this.modelBirthday = !this.isNullOrUndefined(this.model.birthday) ? moment(this.model.birthday).format('DD/MM/yyyy') : moment().format('DD/MM/yyyy');
        this.selectedState = this.model.city.uf.id;
        this.selectedCity = this.model.city.name;
      }, error: err => super.onError(err)
    });

  }

  ngOnDestroy(): void {
    this.eventbus.emit(new EmitEvent(Events.pageSingle, false));
  }

  goBack() {
    this.isPageSingle = false;
    this.toggleLoader(true);
    setTimeout(() => {
      if (!this.model.isValidated) {
        this.onLogout();
      }
      else{
        this.router.navigate(['/']);
      }
      this.toggleLoader(false);
    }, 1000);
  }

  onLogout() {
    localStorage.clear();
    this.userService.isAuthenticated = false;
    this.userService.updateUserData(new User());
    this.router.navigate(['/login']);
  }

  onScriptLoad() {
    console.log('Google reCAPTCHA loaded and is ready for use!');
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }

  onState() {
    this.userService.getState().subscribe({
      next: data => {
        this.listState = data as Uf[];
        if (!super.isNullOrUndefined(super.getUser())) {
          this.selectedState = this.model.city.uf.id;
          this.onCity(this.selectedState);
        }
      }
      , error: err => super.onError(err)
    });
  }

  onCity(id, select?) {
    if (this.isNullOrUndefined(id)) {
      this.listCity = [];
      this.model.city = new City();
    } else {
      const state = this.listState.filter(x => x.id === id);
      // this.model.city.uf = state[0];
      this.userService.getCity(id).subscribe({
        next: data => {
          this.listCity = data as City[];
          if (select) {
            this.onCitySelect(select);
            // $('#promoNumber').focus();
          }
        }, error: err => super.onError(err)
      });
    }
  }

  onCitySelect(id) {
    this.selectedCity = id;
    this.model.city = this.listCity.filter(x => x.name === id)[0];
  }

  onChangeGender(m: string) {
    this.model.sex = m;
  }

  onSave() {
    const arrValidateFields = [
      {value: this.model.cpf, text: 'CPF*, <br />'},
      {value: this.model.name, text: 'Nome*, <br />'},
      {value: this.model.email, text: 'E-mail*, <br />'},
      {value: this.model.sex, text: 'Sexo*, <br />'},
      {value: this.model.birthday, text: 'Data de nascimento*, <br />'},
      {value: this.model.phone, text: 'Telefone*, <br />'},
      {value: this.model.cellPhone, text: 'Celular*, <br />'},
      {value: this.model.zipcode, text: 'CEP*, <br />'},
      {value: this.model.address, text: 'Endereço*, <br />'},
      {value: this.model.number, text: 'Número*, <br />'},
      {value: this.model.city?.uf?.id, text: 'Estado*, <br />'},
      {value: this.model.city?.id, text: 'Cidade*, <br />'},
      {value: this.model.district, text: 'Bairro*, <br />'},
    ];

    if (!this.model.isValidated) {
      // arrValidateFields.push({value: this.promoEmailConfirm, text: 'Confirmar E-mail*, <br />'});
      arrValidateFields.push({value: this.model.password, text: 'Senha*, <br />'});
      arrValidateFields.push({value: this.model.passwordConfirm, text: 'Confirmar Senha*, <br />'});
    }

    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`, 'warning');
      return;
    }
    if (!this.isEMailValid(this.model.email)) {
      return;
    }
    if (!this.model.isValidated) {
      /*if (this.model.email !== this.promoEmailConfirm) {
        super.showMessage('Atenção', 'Os e-mails não são iguais, verifique para prosseguir .', 'warning');
        return;
      }*/
      if (this.model.password !== this.model.passwordConfirm) {
        super.showMessage('Atenção', 'As senhas não são iguais, verifique para prosseguir .', 'warning');
        return;
      }
      if (this.model.password.length < 8) {
        super.showMessage('Atenção', 'O tamanho mínimo da senha é 8 caracteres.', 'warning');
        return;
      }
    }

    if (!this.isCPFValid(this.model.cpf)) {
      return;
    }
    if (!super.dateOfBirthDateValidate(this.model.birthday)) {
      return;
    } else {
      if (!(this.model.birthday instanceof Date)) {
        // @ts-ignore
        if (this.model.birthday.indexOf('T') < 0) {
          this.model.birthday = moment(this.model.birthday, 'DD/MM/YYYY').toDate();
        }
      }
    }
    if (!this.model.isValidated) {
      /*if (!this.myRecaptcha) {
        super.showMessage('Atenção', 'É necessário validar o captcha para prosseguir.', 'warning');
        return;
      }*/
      if (!this.model.isAccept || !this.model.isAcceptLgpd) {
        super.showMessage('Atenção', 'Aceite os termos do regulamento e LGPD para prosseguir.', 'warning');
        return;
      }
    }
    console.log(this.model);
    this.setUserUpdate();
  }

  setUserSave() {
    this.userService.createUser(this.model).subscribe(
      data => {
        const token = data as Token;
        this.setToken(token.authorizationToken);
        this.userService.updateUserData(token.user);
        this.userService.checkAuthenticated();
        this.showMessage('Parabéns', `Cadastro realizado com sucesso.`, 'success');
        this.router.navigate(['/']).then();
      },
      error => this.onError(error)
    );
  }

  setUserUpdate() {
    this.userService.updateUser(this.model).subscribe(
      data => {
        const u = data as User;
        this.userService.updateUserData(u);
        this.showMessage('Parabéns', `Cadastro atualizado com sucesso.`, 'success');
        this.router.navigate(['/']).then();
      },
      error => this.onError(error)
    );
  }

  onZipCodeAutoComplete(event: KeyboardEvent) {
    const a = $(event.target).val();
    if (a.length > 8) {
      this.userService.getCep(a.replace('-', '')).subscribe({
        next: data => {
          const x: any = data;
          this.model.address = x.logradouro;
          this.model.district = x.bairro;
          this.selectedState = x.uf;
          this.onCity(x.uf, x.localidade);
          /*this.modelUser.city_id = x.localidade;
          this.modelUser.state_id = x.uf;*/
          // $('#promoNumber').focus();
        }
      });
    }
  }

  onClearModel(event: FocusEvent, str: string) {
    setTimeout(() => {
      const a = $(event.target).val();
      if (super.isNullOrUndefined(a)) {
        this.model[str] = '';
      }
    });
  }

  onUpdatePassword() {
    const arrValidateFields = [
      {value: this.modelChangePassword.actualPassword, text: 'Senha Atual*, <br />'},
      {value: this.modelChangePassword.newPassword, text: 'Nova Senha*, <br />'},
      {value: this.modelChangePassword.confirmNewPassword, text: 'Confirmar Nova Senha*, <br />'}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage('Atenção', `Preencha os campos obrigatórios: <br><br> ${stringError}`, 'warning');
      return;
    }
    if (this.modelChangePassword.newPassword !== this.modelChangePassword.confirmNewPassword) {
      this.showMessage('Atenção', 'As senhas não correspondem.', 'warning');
      return;
    }
    if (this.modelChangePassword.newPassword.length < 8) {
      this.showMessage('Atenção', 'A senha deve conter no mínimo 8 caracteres.', 'warning');
      return;
    }
    this.userService.changePassword(this.modelChangePassword).subscribe({
      next: data => {
        $('#modalChangePassword').modal('hide');
        super.showMessage('Sucesso!', 'Sua senha foi alterada.', 'success');
      }, error: err => super.onError(err)
    });
  }
}
