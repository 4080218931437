import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../model/user/user';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {PartnerService} from '../../service/partner.service';
import {BaseComponent} from '../../base/base.component';

declare var $: any;

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent extends BaseComponent implements OnInit, OnDestroy {


  isheaderSecondary = true;
  model: User;

  constructor(
    public router: Router,
    public userService: UserService,
  ) {
    super(router);
  }

  ngOnDestroy() {
    // $('.bg-montain').css('background-color', 'initial');
  }

  ngOnInit(): void {
    this.userService.user.subscribe({
      next: data => {
        this.model = data as User;
        if (!this.isNullOrUndefined(this.model.id)) {
          // $('.header').addClass('bg-primary');
        }

      }
    });
    // $('.bg-montain').css('background-color', '#fff');
  }

  handlerDetail() {
    if (this.model.profile === 'master') {
      return true;
    }

    switch (this.model.companyName) {
      case 'stp':
        return true;
      case 'interozone':
        return true;
      case 'tirreno':
        return true;
      case 'wurth':
        return true;
      case 'bosch':
        return true;
      case 'brazzo':
        return true;
      case 'maxi':
        return true;
      default:
        return false;
    }
  }
}
