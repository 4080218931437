import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RankingDetailComponent } from './ranking-detail.component';
import {AngularSvgIconModule} from "angular-svg-icon";



@NgModule({
  declarations: [RankingDetailComponent],
  exports: [
    RankingDetailComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule
  ]
})
export class RankingDetailModule { }
